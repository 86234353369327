.boxDetailPurchase {
    width: 100%;

}

.head-checkout-PayLiq-1 {
    background-color: #e9ecef;
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 10px;
}

.title-checkout-PayLiq {
    text-transform: uppercase;
    font-weight: bold
}

.head-checkout-PayLiq-2{
    padding: 10px 10px;
    border-bottom: solid 1px #e9ecef;
}
.subtitle-checkout-PayLiq{
    text-transform: capitalize;
    font-size: 16px;
}


@media (max-width: 600px) {
	.subtitle-checkout-PayLiq{
        font-size: 14px;
    }
}

