.Container-Apartado-Detail {
	height: 85vh;
	position: relative;
}
.CardApartadoDetail {
	width: 1000px;
	/* height: 500px; */
	height: auto;
	/* background-color: #f1f1f1; */
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 20px;
	display: flex;
	flex-direction: column;

	justify-content: center;
}
.ApartadoDetailSection1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
}
.CardApartadoDetail h2 {
	text-align: center;
	margin: 0;
}

.BodyCardApartadoDetail {
	display: flex;
	padding: 30px 30px;
	gap: 50px;
}
.BodyAdyenCardDetail {
	display: flex;
	padding: 30px 30px;
	gap: 50px;
}
.BoxImgADetail {
	position: relative;
	height: 250px;
	width: 250px;
	background-position: center center;
	background-size: cover;
	object-fit: cover;
	border-radius: 10px;
}
.IconEyeDetail {
	position: absolute;
	top: 92%;
	left: 100%;
	transform: translate(-50%, -50%);
}
.ApartadoDetailSection2 {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
}
.AdyenCardDetailSection2 {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 100%;
	align-items: center;
}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected{
	color: #fff!important;
	background-color: #cbb878!important;
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root {
    border: 1px solid #cbb878!important;
    color: #cbb878!important;
}
.containerFlexBetween {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.containerFlexColum {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}
.containerFlexStart {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* display: flex;
	justify-content: flex-end;
	align-items: center; */
}
.subtitlesdetail {
	color: #000000a8;
	font-weight: 600;
}
.textPrecio {
	font-size: 40px;
	color: #000000;
}
.Dvividerhr {
	height: 0.5px;
	border: 0;
	background: #0000002a !important;
	opacity: 1;
	width: 100%;
	margin: 0;
	padding: 0;
}

.apartalo {
	text-align: center !important;
	font-size: 20px;
	font-weight: 600;
	margin-top: 10px !important;
}

@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.2); }
    to { transform: none; }
}
.textPrecioApartado {
	text-shadow: 0 0 10px #222,1px 1px  0 #222;
	color: #000;
	animation: latidos 1s infinite;
	transform-origin: center;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.Container-Apartado-Detail {
		height: 60vh;
	}
	.CardApartadoDetail {
		width: 95%;
		height: auto;
	}
	.ApartadoDetailSection1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
	}
	.CardApartadoDetail h2 {
		font-size: 25px;
	}
	.BodyCardApartadoDetail {
		display: flex;
		padding: 0px 30px;
		gap: 50px;
	}
	.BodyAdyenCardDetail {
		display: flex;
		padding: 0px 30px;
		gap: 50px;
	}
	
	.BoxImgADetail {
		position: relative;
		height: 200px;
		width: 200px;
	}
	.IconEyeDetail {
		position: absolute;
		top: 92%;
		left: 100%;
		transform: translate(-50%, -50%);
	}
	.ApartadoDetailSection2 {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		padding: 20px 0px;
	}
	.AdyenCardDetailSection2 {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		padding: 20px 0px;
	}
	
	.containerFlexBetween {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
	}
	.containerFlexStart {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* display: flex;
		justify-content: flex-end;
		align-items: center; */
	}
}

/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.Container-Apartado-Detail {
		height: 80vh;
	}
}
@media (max-width: 600px) {
	.Container-Apartado-Detail {
		height: 148vh;
		position: relative;
	}
	.CardApartadoDetail {
		width: 90%;
		height: auto;
	}
	.ApartadoDetailSection1 {
		align-items: center;
	}
	.CardApartadoDetail h2 {
		font-size: 25px;
	}
	.BodyCardApartadoDetail {
		display: flex;
		flex-direction: column;
		padding: 0px 30px;
		gap: 50px;
	}
	.BodyAdyenCardDetail {
		display: flex;
		flex-direction: column;
		padding: 0px 30px;
		gap: 50px;
	}
	
	.BoxImgADetail {
		position: relative;
		height: 200px;
		width: 200px;
		background-position: center center;
		background-size: cover;
		object-fit: cover;
		border-radius: 10px;
	}
	.IconEyeDetail {
		position: absolute;
		top: 92%;
		left: 100%;
		transform: translate(-50%, -50%);
	}
	.ApartadoDetailSection2 {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		margin-bottom: 10px;
	}
	.AdyenCardDetailSection2 {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: 100%;
		margin-bottom: 10px;
	}
	
	.containerFlexBetween {
		flex-direction: column;
		align-items: start;

		gap: 10px;
	}
	.containerFlexStart {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.apartalo {
		font-size: 20px !important;
	}
}

/* ******************************************** CARD DE PAGOS CON STEPPER ************************************************* */
.CardApartadoDetailPagos {
	width: 1000px;
	/* height: 450px; */
	height: auto;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	margin-top: 5rem;
}
.CardAdyenCard {
	width: 1000px;
	/* height: 450px; */
	height: auto;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	margin-top: 1rem;
}
.CardStatusAdyen {
	width: 1000px;
	/* height: 450px; */
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	margin-top: 5rem;
}
.CardApartadoDetailPagosContainerStepper {
	width: 1000px;
	height: auto;
	background-color: transparent;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	/* margin-top: 3rem; */
	padding-bottom: 5rem;
}
.CardApartadoDetailPagosCardStepper {
	width: 1000px;
	height: auto;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	margin-top: 2rem;
	padding: 20px 0px;
}
.CardApartadoDetailPagosCardStepper h2 {
	text-align: center;
	margin: 0;
}

.container-Checkbox-ApatadoPagos {
	background-color: #00000009;
	border-radius: 7px 7px 7px 7px;
	padding: 10px 20px 10px 20px;
	display: flex;
	align-items: center;
}
.containerSelectDetailPago {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.CardStatusAdyen {
		width: 95%;
		height: auto;
	}
	.CardApartadoDetailPagos {
		width: 95%;
		height: auto;
	}
	.CardAdyenCard {
		width: 95%;
		height: auto;
	}
	.CardApartadoDetailPagosContainerStepper {
		width: 95%;
		height: auto;
		margin-top: 3rem;
	}
	.CardApartadoDetailPagosCardStepper {
		width: 95%;
		height: auto;
	}
	.CardApartadoDetailPagosCardStepper h2 {
		font-size: 25px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
}
@media (max-width: 600px) {
	.CardStatusAdyen {
		width: 90%;
		height: auto;
		margin-top: 3rem;
	}
	.CardApartadoDetailPagos {
		width: 90%;
		height: auto;
		margin-top: 3rem;
	}
	.CardAdyenCard {
		width: 90%;
		height: auto;
		margin-top: 3rem;
	}
	.CardApartadoDetailPagosContainerStepper {
		width: 95%;
		height: auto;
		margin-top: 2rem;
	}
	.CardApartadoDetailPagosCardStepper {
		width: 95%;
		height: auto;
	}
	.CardApartadoDetailPagosCardStepper h2 {
		font-size: 25px;
	}
}
