.ImgComingSoon{
    /* background-image: url(../../Images/ComingSoon.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; */
    height: auto;
    width: 100%;
}
.txt-Footer-ComingSoon{
    text-align: center;
}
.AppbarComingSoon{
    box-shadow: none !important;
}
/* .PositionImgComingSoon{
    justify-content: center;
} */

    /* cambios tablets css solo para modo Vertical */
    @media (max-width: 900px) {
        .PositionImgComingSoon{
            justify-content: center;
        }
    }
    /* cambios tablets css solo para modo horizontal */
    @media only screen
    and (min-device-width: 641px)
    and (max-device-width: 1180px)
    and (orientation: landscape)
    {
    }