.container-one-pay {
	width: 60%;
}

.box-proceso-producto-pay {
	display: flex;
	padding: 0px 20px 0px 10px;
	width: 100%;
	border-radius: 20px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.head-one-pay-section-1 {
	width: 20%;
	padding: 20px;
}

.head-one-pay-section-2 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80%;
	height: 100%;
}

.title-head-one-pay {
	font-size: 25px;
	font-weight: bold;
	padding-left: 10px;
}

.subtitle-head-one-pay {
	font-size: 16px;
	padding-left: 10px;
}

.box-paycodi-stepper {
	width: 100%;
	padding: 20px;
	margin-top: 20px;
	border-radius: 20px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.body-stepper {
	height: 90%;
}

.footer-stepper {
	display: flex;
	justify-content: space-between;
	height: 10%;
}

/* paycodi */
.btn-inf-codi {
	width: 40px;
	height: 40px;
	margin-left: 20px !important;
	background-color: #b08968 !important;
}

.icon-inf-codi {
	color: #f2f4f3 !important;
}

.title-pay-liq {
	text-align: center;
}

.subtitle-pay-liq {
	text-align: center;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.container-one-pay {
		width: 95%;
	}
	.box-proceso-producto-pay {
		width: 95%;
	}
	.box-paycodi-stepper {
		width: 95%;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.container-one-pay {
		width: 95%;
	}
	.box-proceso-producto-pay {
		width: 95%;
	}
	.box-paycodi-stepper {
		width: 95%;
	}
}

@media (max-width: 600px) {
	.container-one-pay {
		width: 95%;
	}

	.head-one-pay-section-1 {
		width: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
	}

	.title-head-one-pay {
		font-size: 20px;
		font-weight: bold;
		padding-left: 10px;
		line-height: 0px;
	}

	.subtitle-head-one-pay {
		font-size: 14px;
		padding-left: 10px;
	}

	.title-pay-liq {
		font-size: 16px;
		text-align: left;
	}

	.subtitle-pay-liq {
		text-align: left;
	}
	.box-proceso-producto-pay {
		width: 90%;
	}
	.box-paycodi-stepper {
		width: 90%;
	}
}
