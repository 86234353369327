@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
@font-face {
	font-family: Cinzel-Regular;
	src: url(../../Util/fonts/Cinzel/Cinzel-Regular.ttf);
}
/*? SECTION 1 EMPEÑO MUNDO BARTER */
.ApartadoSection1MB {
	background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Apartados/catego.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	height: 90vh;
    display: flex;
}
.ApartadoSection1MB  .body{
display: flex;
justify-content: center;
align-items: center;
}
.ApartadoSection1MB .body .contentSection2MB{
width: 50%;
}
.ApartadoSection1MB .body .contentSection1MB{
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
gap: 10px;
margin-left: 60px;
}
.ApartadoSection1MB .body .contentSection1MB .titlesection2MB{
	color: #161616;
	font-size: 80px;
	line-height: 80px;
	margin: 0 !important;
	font-weight: 400;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
}
.ApartadoSection1MB .body .contentSection1MB .subtitlesection2MB{
    line-height: 35px;
	font-size: 35px;
	font-weight: 400;
    color: #000000;
	font-family: Baskervville-Regular !important;
    width: 68%;
}
.ApartadoSection1MB .body .contentSection1MB .subtitlesection2MB .bottomLink{
    display: flex;
}
.ApartadoSection1MB .body .contentSection1MB  .bottomLink .btnLink{
    background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	/* border-radius: 100px; */
}
.ApartadoSection1MB .body .contentSection1MB  .bottomLink .btnLink:hover{
    /* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
    /* background-color: #757d6593; */
    background-color: #252525;
}
/* cambios pantallas resolución desktop 1366  */
@media (max-width: 1366px) {
    .ApartadoSection1MB {
        height: 90vh;
    }
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .ApartadoSection1MB {
        height: 400px;
    }
    .ApartadoSection1MB .body .contentSection1MB{
    margin-left: 20px;
    }
    .ApartadoSection1MB .body .contentSection1MB .titlesection2MB{
        font-size: 40px;
        line-height: 40px;
    }
    .ApartadoSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 26px;
        font-size: 26px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
    }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .ApartadoSection1MB {
        height: 550px;
    }
  
    .ApartadoSection1MB .body .contentSection1MB{   
    margin-left: 20px;
    }
    .ApartadoSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 26px;
        font-size: 26px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
    }
}
@media (max-width: 600px) {
    .ApartadoSection1MB {
        height: 289px;
    }
    .ApartadoSection1MB  .body{
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 20px;
    }
    .ApartadoSection1MB .body .contentSection2MB{
    width: 40%;
    }
    .ApartadoSection1MB .body .contentSection1MB{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 10px;
    }
    .ApartadoSection1MB .body .contentSection1MB .titlesection2MB{
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }
    .ApartadoSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        margin: 9px 0px;
        width: 69%;
    }
    .ApartadoSection1MB .body .contentSection1MB  .bottomLink .btnLink{
        font-size: unset;
        border-radius: 4px;
        background-color: #000000;
    }
}

/*? SECTION 2 EMPEÑO MUNDO BARTER */
.ApartadoSection2MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.ApartadoSection2MB .cardESection3{
    width: 1300px;
}
.ApartadoSection2MB .cardESection3 p{
    font-weight: 400;
    font-size: 50px;
    line-height: 72px;
   color:#BD7439;
   font-family: Baskervville-Regular !important;
   margin: 0;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs{
  width: 85%;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   gap: 90px;
}

.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information{
   display: flex;
   /* align-items: baseline; */
   align-items: center;
   width: 60%;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .number{
    font-size: 350px;
    line-height: 420px;
    color: rgba(225, 194, 144, 1);
    font-weight: 400;
    font-family: Cinzel-Regular !important;
    opacity: 30%;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .text{
    color: #000;
    font-size: 20px;
    line-height: 19.2px;
    font-weight: 400;
    font-family: Baskervville-Regular !important;
    width: 50%;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .text a{
    color: #000;
    font-size: 20px;
    line-height: 19.2px;
    font-weight: 400;
    font-family: Baskervville-Regular !important;
    cursor: pointer;
    text-decoration: underline ;
    text-decoration-color: #000;
    text-decoration-thickness: 1.2px;
    -moz-text-decoration-color: #000;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg{
 background-image:
 url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Apartados/DSC08878.png');
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
 height: 312px;
 width: 40%;
 border-radius: 4px;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg2{
 background-image:
 url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Apartados/DSC09188.png');
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
 height: 312px;
 width: 40%;
 border-radius: 4px;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg3{
 background-image:

  url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Apartados/DSC01271.png');
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
 height: 312px;
 width: 40%;
 border-radius: 4px;
}
.ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg4{
 background-image:
 url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Apartados/DSC05219.png');
 background-repeat: no-repeat;
 background-size: cover;
 background-position: bottom center;
 height: 312px;
 width: 40%;
 border-radius: 4px;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .ApartadoSection2MB{
        padding:  0px 10px;
    }
    .ApartadoSection2MB .cardESection3{
        width: 100%;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information{
        align-items: center;
      
     }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .ApartadoSection2MB{
        padding:  0px 10px;
    }
    .ApartadoSection2MB .cardESection3{
        width: 100%;
    }
}
@media (max-width: 600px) {
    .ApartadoSection2MB{
        padding:  0px 10px;
    }
    .ApartadoSection2MB .cardESection3{
        width: 100%;
    }
    .ApartadoSection2MB .cardESection3 p{
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs{
      width: 100%;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions{
       width: 100%;
       gap: 10px;
    }
    
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information{
       align-items: center;
       width: 50%;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .number{
        font-size: 200px;
        line-height: 240px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .text{
        font-size: 16px;
        line-height: 16px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg{
        height: 200px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg2{
        height: 200px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg3{
        height: 200px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .contentimg4{
        height: 200px;
    }
    .ApartadoSection2MB .cardESection3 .ContainerInstructions .css-1c2fuzs .cardinstructions .information .text a{
        color: #000;
        font-size: 16px;
        line-height: 16px;
    }
}

/*? SECTION 3 EMPEÑO MUNDO BARTER */
.ApartadoSection3MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 125px;
}
.ApartadoSection3MB .cardESection3{
    width: 1300px;
    display: flex;
   align-items: center;
    flex-direction: column;
    gap: 50px;
}
.ApartadoSection3MB .cardESection3 .Logo{
    width: 30%;
}
.ApartadoSection3MB .cardESection3 .text{
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Baskervville-Regular !important;
}
.ApartadoSection3MB .cardESection3 .text{
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Baskervville-Regular !important;
}
.ApartadoSection3MB .cardESection3 .text .bottomLink{
    display: flex;
}
.ApartadoSection3MB .cardESection3 .bottomLink .btnLink{
    background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
    font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	/* border-radius: 100px; */
}
.ApartadoSection3MB .cardESection3 .bottomLink .btnLink:hover{
    /* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
    background-color: #252525;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
  
    .ApartadoSection3MB{
        margin-top: 60px;
        padding: 0px 10px;
    }
    .ApartadoSection3MB .cardESection3{
        width: 100%;
    }
   
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .ApartadoSection3MB{
        margin-top: 60px;
        padding: 0px 10px;
    }
    .ApartadoSection3MB .cardESection3{
        width: 100%;
    }
}
@media (max-width: 600px) {
  
    .ApartadoSection3MB{
        margin-top: 60px;
        padding: 0px 10px;
    }
    .ApartadoSection3MB .cardESection3{
        width: 100%;
        gap: 30px;
    }
    .ApartadoSection3MB .cardESection3 .Logo{
        width: 50%;
    }
    .ApartadoSection3MB .cardESection3 .text{
        font-size: 16px;
        line-height: 19.2px;
    }
    .ApartadoSection3MB .cardESection3 .bottomLink .btnLink{
        font-size: unset;
        border-radius: 4px;
        background-color: #000000;
    }
  
   
}