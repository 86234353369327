@font-face {
  font-family: CarlaSansLight;
  src: url(../../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
  font-family: Baskervville-Regular;
  src: url(../../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
  font-family: Rosario-Regular;
  src: url(../../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
@font-face {
  font-family: Montserrat-Regular;
  src: url(../../../Util/fonts/Montserrat/Montserrat-Regular.ttf);
}
.ContainerFooter {
  background-color: #f6efde;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.ContainerFooter .cardFooter {
  width: 100%;
  padding: 60px 60px;
  display: flex;
  flex-direction: column;
}
.ContainerFooter .cardFooter .sectionf1 {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.ContainerFooter .cardFooter .sectionf1 .columns {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 33.33%;
}
.ContainerFooter .cardFooter .sectionf1 .columns .title {
  color: #000;
  line-height: 24.18px;
  font-size: 30px;
  font-family: Baskervville-Regular !important;
}
.ContainerFooter .cardFooter .sectionf1 .columnscontact {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 33.33%;
}
.ContainerFooter .cardFooter .sectionf1 .columnscontact .columninfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ContainerFooter .cardFooter .sectionf1 .columnscontact .title {
  color: #000;
  /* line-height: 24.18px; */
  font-size: 30px;
  font-family: Baskervville-Regular !important;
}
.ContainerFooter .cardFooter .sectionf1 .columnscontact .spanHeaderMb {
  color: #000000;
  font-size: 18px;
  /* line-height: 16.12px; */
  font-weight: 500 !important;
  font-family: Rosario-Regular !important ;
  /* text-transform: capitalize; */
  text-align: start;
}
.ContainerFooter .cardFooter .sectionf1 .columns .spanHeaderMb {
  color: #000000;
  font-size: 20px;
  line-height: 16.12px;
  font-weight: 500 !important;
  font-family: Rosario-Regular !important ;
  /* text-transform: capitalize; */
  text-align: start;
}
.ContainerFooter .cardFooter .sectionf1 .columns .BtnHeaderMb {
  color: #000000;
  font-size: 20px;
  line-height: 16.12px;
  font-weight: 500 !important;
  position: relative;
  display: inline-block;
  font-family: Rosario-Regular !important ;
  text-transform: initial;
  text-align: start;
}
.ContainerFooter .cardFooter .sectionf1 .columns .BtnHeaderMb::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 1.5px;
  left: 50%;
  position: absolute;
  background: rgb(183, 183, 183);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.ContainerFooter .cardFooter .sectionf1 .columns .BtnHeaderMb:hover::after {
  width: 100%;
  left: 0;
}
.ContainerFooter .cardFooter .DividerFooter {
  width: 100% !important;
  background-color: #000 !important;
  margin: 30px 0px;
}
.ContainerFooter .cardFooter .sectionf2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ContainerFooter .cardFooter .sectionf2 img {
  width: 12%;
}
.ContainerFooter .cardFooter .sectionf2 .redesSociales {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.ContainerFooter .cardFooter .sectionf2 .redesSociales img {
  width: 40px;
}

.ContainerFooter .cardFooter .sectionf2 .derechosR {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-family: Rosario-Regular !important ;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
  .ContainerFooter .cardFooter {
    width: 100%;
    padding: 50px 10px;
  }
  .ContainerFooter .cardFooter .sectionf1 .columns {
    width: 33.33%;
  }
  .ContainerFooter .cardFooter .sectionf1 .columnscontact {
    width: 33.33%;
  }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
}
@media (max-width: 600px) {
}
