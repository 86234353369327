.container-menu {
	/* height: 100px; */
	z-index: 100 !important;
	backdrop-filter: blur(5px) !important;
	position: sticky;
	top: 0;
	padding: 0px !important;
	margin: 0px !important;
}

.container-items-header {
	background-color: rgba(0, 0, 0, 0.85);
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 85px;
	position: relative;
}

.container-div {
	color: rgb(246, 246, 246) !important;
}

.btn-header-lenguage {
	color: rgb(246, 246, 246) !important;
	font-weight: bold !important;
}

.container-btn-burger {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.icon-btn-login {
	color: #fff !important;
	margin-right: 20px !important;
}

.logo-header {
	width: 150px;
	margin-right: 50px;
	user-select: none !important;
}

.container-sidebar {
	display: flex;
	flex-direction: column;
	background-color: rgb(0, 0, 0);
	width: 500px;
	height: 1220px;
	position: absolute;
	left: 0px;
	top: 0px;
	padding: 20px;
	z-index: 1;
}

.container-header-sidebar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container-item-a {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	padding: 0px 20px;
	color: var(--white);
	animation-delay: 0.25s;
	animation: fadeIn 0.5s linear;
	animation-fill-mode: both;
}

.container-item-a-w80 {
	display: flex;
	flex-direction: column;
	margin: 10px 0px;
	color: var(--white);
	animation-delay: 0.25s;
	animation: fadeIn 0.5s linear;
	animation-fill-mode: both;
}

.a-item-sidebar {
	text-decoration: none;
	color: rgb(255, 255, 255);
	padding-top: 10px;
	border-bottom: solid 1px rgba(238, 238, 238, 0.13);
	cursor: pointer;
	animation-delay: 0.25s;
	animation: fadeIn 0.5s linear;
	animation-fill-mode: both;
}

.a-item-sidebar p {
	color: rgb(255, 255, 255);
	font-size: 16px;
}

/* menu usario */
.container-Account-Menu {
	backdrop-filter: blur(5px) !important;
}

.container-items-sidecar {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding: 0px 20px;
	max-height: 600px;
	overflow: auto;
}

.icon-delete-item-sidebar {
	font-size: 30px !important;
	color: #ffffff !important;
}

.Chip-login {
	padding: 0px 20px !important;
	font-size: 16px !important;
	/* background-color: #583101 !important; */
	background-color: #c58989 !important;
	/* color:#ffedd8 !important; */
	color: #fff !important;
	/* border-radius:50px!important; */
	height: 45px !important;
	text-transform: capitalize !important;
}

.Chip-login-line {
	padding: 0px 20px !important;
	font-size: 16px !important;
	background-color: #ffffff !important;
	color: #000000 !important;
	border: solid 1.5px #c58989 !important;
	/* border-radius:50px!important; */
	height: 45px !important;
	text-transform: capitalize !important;
}

/* top | right | bottom | left */
.Chip-login-success {
	padding: 10px 20px 10px 15px !important;
	font-size: 16px !important;
	background-color: #000000 !important;
	color: #fff !important;
	/* border-radius:50px!important; */
	height: 45px !important;
	text-transform: capitalize !important;
	text-align: left !important;
	margin-right: 10px !important;
}
.widthToolbarHeaderClient {
	width: 90%;
	display: flex;
	justify-content: space-between;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.logo-header {
		width: 100px;
	}
	.icon-btn-login {
		margin-right: 5px !important;
	}

	.container-menu {
		height: 80px;
	}

	.container-items-header {
		display: flex;
		justify-content: space-between;
		height: 80px;
	}

	.container-sidebar {
		width: 370px;
		padding: 0px 10px;
		max-height: 100vh;
		z-index: 1;
		overflow: auto;
	}

	.container-item-a {
		margin-top: 20px;
	}

	.a-item-sidebar p {
		font-size: 12px;
		text-transform: uppercase;
	}
	.Chip-login {
		padding: 0px 10px !important;
		font-size: 10px !important;
		/* height: 30px !important; */
		min-width: auto !important;
	}

	.Chip-login-line {
		padding: 0px 10px !important;
		font-size: 10px !important;
		/* height: 30px !important; */
		min-width: auto !important;
	}

	/* side bar car */
	.container-items-sidecar {
		padding: 0px;
		max-height: 400px;
	}
}
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.logo-header {
		width: 100px;
	}
	.icon-btn-login {
		margin-right: 5px !important;
	}

	.container-menu {
		height: 80px;
	}

	.container-items-header {
		display: flex;
		justify-content: space-between;
		height: 80px;
	}

	.container-sidebar {
		width: 370px;
		padding: 0px 10px;
		max-height: 100vh;
		z-index: 1;
		overflow: auto;
	}

	.container-item-a {
		margin-top: 20px;
	}

	.a-item-sidebar p {
		font-size: 12px;
		text-transform: uppercase;
	}

	/* side bar car */
	.container-items-sidecar {
		padding: 0px;
		max-height: 400px;
	}
	.widthToolbarHeaderClient {
		width: 95%;
	}
	.Chip-login-success {
		padding: 10px 10px 10px 15px !important;
		font-size: 10px !important;
		background-color: #757D65 !important;
		color: #fff !important;
		/* border-radius:50px!important; */
		height: 50px !important;
		text-transform: capitalize !important;
		text-align: left !important;
		margin-right: 10px !important;
	}
}
/*?::::::::::::::::::::::::::::::: BREAK POINT 600PX ::::::::::::::::::::::::::::::: */

@media screen and (max-width: 600px) {
	.widthToolbarHeaderClient {
		width: 94%;
	}
	.icon-btn-login {
		margin-right: 5px !important;
	}

	.container-menu {
		height: 80px;
	}

	.container-items-header {
		display: flex;
		justify-content: space-between;
		height: 80px;
	}

	.container-sidebar {
		width: 370px;
		padding: 0px 10px;
		max-height: 100vh;
		z-index: 1;
		overflow: auto;
	}

	.container-item-a {
		margin-top: 20px;
	}

	.a-item-sidebar p {
		font-size: 16px;
		text-transform: uppercase;
	}
	.Chip-login {
		padding: 0px 20px !important;
		font-size: 13px !important;
	}

	.Chip-login-line {
		padding: 0px 20px !important;
		font-size: 13px !important;
	}

	/* side bar car */
	.container-items-sidecar {
		padding: 0px;
		max-height: 400px;
	}
}

/* :::::::::::::::::::::::::::::::::::::::::::	ModalMundoBarter ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.container-modalMB {
	display: flex;
	height: 100%;
}
.section1MB {
	width: 45%;
}
.section2MB {
	width: 55%;
}
.imgModalMB {
	width: 100%;
	height: 100%;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

@media screen and (max-width: 600px) {
	.container-modalMB {
		display: flex;
		flex-direction: column-reverse;
		max-height: 90vh;
		overflow: auto;
	}
	.section2MB {
		width: 100%;
		flex-direction: column;
	}
	.section1MB {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.imgModalMB {
		width: 90%;
		height: 100%;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		margin-bottom: 20px;
	}
}
