@font-face {
	font-family: HelveticaNeue;
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf); */
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf); */
	src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeueLight.ttf);
}

@font-face {
	font-family: GothamLight;
	src: url(../Util/fonts/Gotham/GothamLight.ttf);
}

@font-face {
	font-family: AvenirLight;
	src: url(../Util/fonts/Avenir/Avenir-Light.ttf);
}
@font-face {
	font-family: HelveticaNeueMedium;
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf); */
	src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf);
}

/* FONTS FOUNDIT */
@font-face {
	font-family: CarlaSansLight;
	src: url(../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../Util/fonts/Rosario/Rosario-Regular.ttf);
}
/* Drawer */
.css-h4y409-MuiList-root {
	list-style: none !important;
	margin: 0 !important;
	padding: 0 !important;
	position: relative !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.css-1045bwl-MuiButtonBase-root-MuiListItem-root {
	padding-top: 1px !important;
	padding-bottom: 1px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
}

.css-1wq6pf6-MuiListItem-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.css-15v22id-MuiAccordionDetails-root {
	padding: 8px 16px 0px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
	margin: 0px 0 !important;
}

/* CLASES PRODUCCION */

.css-semhhs {
	padding: 0px 16px !important;
}

.css-10wg8gr {
	padding: 0px 16px !important;
}

.css-semhhs {
	padding: 0px 16px !important;
}

.css-u7qq7e {
	padding: 0px 16px 0px !important;
}

.css-10wg8gr {
	padding: 0px 16px !important;
}

.css-67l5gl.Mui-expanded {
	margin: 0px 0px !important;
}

/* DISPLAY */
.d-flex {
	display: flex;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f8c471;
	border-radius: 5px;
	width: 520px;
	height: 50px;
	padding: 0px 15px 0px 15px;
	border: 1px solid #9c640c;
	margin-bottom: 10px;
}

.container-folio {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	width: 200px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #9c640c;
	margin-left: 20px;
}

.containerAlignHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: space-between;
}

.title-solicitud {
	font-size: 16px;
	font-weight: bold;
	margin-right: 10px;
	color: #9c640c;
}

.subtitle-name {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	color: #9c640c;
}

.containerCanvas {
	/* display: flex; */
	align-items: top;
	/* background-color: #f2f7ff; */
	background-color: #9eb5ae2a;
	width: 100%;
	min-height: 100vh;
}

.containerViewUser {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: #9eb5ae00;
	/* margin-top: 20px !important; */
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.paddingTablets {
		padding: 40px 0px !important;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.paddingTablets {
		padding: 40px 0px !important;
	}
}
@media (max-width: 600px) {
	.titleCuestionario {
		font-size: 20px;
	}
	.paddingTablets {
		padding: 40px 0px !important;
	}
	.containerViewUser {
		width: 95%;
		margin: 0 auto;
	}
}

.containerViews {
	padding: 5px 30px 5px 30px !important;
	direction: row;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	border-radius: 20px;
	min-height: 100vh;
	width: 100%;
}

.containerViews2 {
	padding: 5px 30px 5px 30px !important;
	direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	height: 100vh;
	width: 100%;
}

.fullContainerView {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 100%;
	height: 100vh;
}

.container-paper {
	padding: 0px 10px 0px 10px;
	border-radius: 20px;
	border-radius: 20px;
}

.container-header-icon {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.container-footer-icon {
	display: flex;
	align-items: center;
}

.ziseIcon {
	font-size: 35px !important;
	color: #d8f3dc !important;
}

.container-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 36, 107, 0.7);
	border-radius: 50%;
	padding: 5px;
	margin-right: 10px;
	width: 45px;
	height: 45px;
}

.container-icon2 {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	padding: 5px;
	margin-right: 10px;
	width: 45px;
	height: 45px;
}

.text-paper {
	font-size: 20px;
	color: #fff;
	font-weight: bold;
}

.text-paper2 {
	font-size: 20px;
	font-weight: bold;
}

.title-number {
	font-size: 16px;
	color: #fff;
}

.title-number1 {
	font-size: 20px;
	font-weight: bold;
	margin-right: 10px;
	color: #fff;
}

.title-views {
	margin-bottom: 20px;
	color: #fff;
}

.text-link {
	text-decoration: none;
	font-weight: bold !important;
}

.text-link2 {
	text-decoration: none;
	font-weight: bold !important;
	color: #d35400 !important;
}

.text-link-items {
	text-decoration: none;
	font-weight: bold !important;
	text-align: 'left';
}

.text-link-items2 {
	text-decoration: none;
	font-weight: bold !important;
	text-align: 'left';
	color: #d35400 !important;
}

.text-link-ac {
	text-decoration: none;
}

.text-link-items-ac {
	text-decoration: none;
	text-align: 'left';
	color: #d35400 !important;
}

.icon-link-items {
	color: #d35400;
}

.dialog-content-modal {
	width: 500px;
	height: 210px;
}

.dialog-content-modal2 {
	width: 500px;
	height: 210px;
}

.dialog-content-modal-Pass {
	width: 500px;
	height: 290px;
}
@media (max-width: 600px) {
	.dialog-content-modal-Pass {
		width: auto !important;
		height: auto !important;
	}
}

.btn-add-modal {
	background-color: #b7e4c7 !important;
	color: #1b4332 !important;
	padding: 5px 30px 5px 30px !important;
	font-weight: bold !important;
	margin-left: 30px !important;
}

.btn-close-modal {
	background-color: none !important;
	color: red !important;
	font-weight: bold !important;
}

.btn-close-icon-modal {
	color: gray !important;
}

.containerHeaderModal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: solid 1px rgba(43, 45, 66, 0.1);
	border-bottom-width: 80%;
}

.btn-Header {
	background-color: #fddcf9 !important;
	color: #d81b60 !important;
	border: solid 1px #d81b60 !important;
	border-radius: 20px !important;
	padding: 5px 30px 5px 30px !important;
	font-weight: bold !important;
	text-decoration: none !important;
	margin-right: 30px !important;
}

.btn-editar {
	width: 40px !important;
	height: 40px !important;
	border-radius: 10px !important;
	background-color: #d0ece7 !important;
	color: #0e6655 !important;
	font-weight: bold !important;
	border: solid 1px #0e6655 !important;
}

.btn-eliminar {
	width: 40px !important;
	height: 40px !important;
	border-radius: 10px !important;
	background-color: #e4b1ab !important;
	color: #38040e !important;
	font-weight: bold !important;
	border: solid 1px #38040e !important;
}

.btn-footer {
	background-color: #f5b041 !important;
	color: #7e5109 !important;
	border: solid 1px #7e5109 !important;
	border-radius: 20px !important;
	padding: 5px 30px 5px 30px !important;
	font-weight: bold !important;
	text-decoration: none !important;
	margin-top: 30px !important;
}

.tss-1bk4gez-MUIDataTable-root {
	border-radius: 12px !important;
	box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2) !important;
}

.tss-1bk4gez-MUIDataTable-root h6 {
	color: #d81b60;
}

.dialog-help {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;
	height: 500px;
	background: rgba(255, 255, 255, 0.313);
	border: none;
	border-radius: 10px;
	color: rgba(255, 255, 255, 0.078);
	backdrop-filter: blur(5px) !important;
	object-fit: cover;
}

::-webkit-scrollbar-thumb {
	/* background: rgba(255, 123, 0, 0.4); */
	background: #d7d1c4;
	/*border-radius: 10px;*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #d7d1c4;
	/* background: rgba(255, 160, 0, 1); */
}

::-webkit-scrollbar {
	height: 5px;
	/* height of horizontal scrollbar ← You're missing this */
	width: 5px;
	/* width of vertical scrollbar */
	border: 1px #d7d1c4;
}

/*-----------------------------------------    Media Query  ------------------------------------------- */

@media (max-width: 1366px) {
	.text-paper {
		font-size: 12px;
	}

	.text-paper2 {
		font-size: 12px;
	}

	.title-number1 {
		font-size: 12px;
	}

	.title-number {
		font-size: 12px;
	}

	.container-header-icon {
		width: 100%;
	}

	.container-icon {
		width: 30px;
		height: 30px;
	}

	.ziseIcon {
		font-size: 20px !important;
		color: #d8f3dc !important;
	}

	.containerViews {
		max-height: 530px;
	}
}

/*Pantalla de equipo portátil de 13 a 15 pulgadas con relación estándar*/
@media (max-width: 1366px) {
	.containerViews {
		/* height: 540px; */
		width: 1000px;
	}

	.containerCanvas {
		/* height: 660px; */
	}

	.paperMarginTop {
		margin-top: 0px;
		padding: 0px 0px 0px 50px;
	}

	.paperMargin {
		margin: 0px 10px 0px 10px;
	}

	.form-m {
		margin: 8px 0px 0px 0px;
		/* arriba, derecha, abajo,izquierda */
	}
}

.btnLogin {
	text-transform: capitalize !important;


	line-height: 20px;
    font-weight: 500 !important;
    font-size: 20px !important;
    display: inline-block !important;
    color: #fff !important;
    text-transform: none !important;
    background-color: #000000 !important;
    font-family: Rosario-Regular !important;
}
.btnCancel {
	background-color: #9EB5AE !important;
	/* background-color: #9EB5AE !important; */
	color: #fff !important;
	font-weight: 500 !important;
}

.DialogEvaluationBtnAcep {
	background-color: #757D65 !important;
	color: #fff !important;
	font-weight: 600 !important;
}

.DialogEvaluationBtnCancel {
	/* background-color: #CBB878 !important; */
	border: 1px solid #cbb878 !important;
	color: #000 !important;
	font-weight: 500 !important;
}

/* ::::::::::::::::::::::::::::::::::::::		CalendarReschedule		:::::::::::::::::::::::::::::::::::::::::::::: */
.header-calendar {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(0, 0, 0);
	border-radius: 10px 10px 0px 0px;
	padding: 10px;
}

.Table-Schedule {
	margin-top: 16px;
}

.column-ahours {
	width: fit-content;
}

.box-schedule {
	width: 100%;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.header-calendar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.box-schedule {
		max-width: 600px;
		overflow: auto;
	}

	.Table-Schedule {
		margin-top: 16px;
	}

	.column-ahours {
		width: 20% !important;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.header-calendar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.box-schedule {
		max-width: 800px;
		overflow: auto;
	}

	.Table-Schedule {
		margin-top: 16px;
	}

	.column-ahours {
		width: 20% !important;
	}
}
@media (max-width: 600px) {
	.header-calendar {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.box-schedule {
		max-width: 600px;
		overflow: auto;
	}

	.Table-Schedule {
		margin-top: 16px;
	}

	.column-ahours {
		width: 20% !important;
	}
}

.HeaderDialogEvaluation {
	background-color: #757D65!important;
	color: #fff !important;
	font-weight: 600 !important;
}

@media (max-width: 600px) {
	.HeaderDialogEvaluation {
		font-size: 18px !important;
	}
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::	CpropuestaTermsConditions 	:::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
.box-termConditions-MB {
	background-color: rgb(255, 255, 255);
	width: 686px;
	height: fit-content;
	padding-bottom: 10px;
	margin: auto;
	border-radius: 7px 7px 7px 7px;
	padding: 10px;
}

.section-termConditions-MB-1 {
	height: 90%;
}

.section-termConditions-MB-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10%;
}

.box-term-MB-1 {
	padding: 10px;
	max-height: 600px;
	overflow: auto;
	margin-bottom: 30px;
}

@media (max-width: 600px) {
	.box-termConditions-MB {
		width: 95%;
		height: 95vh;
		background-color: #ffffff;
		padding: 10px;
	}

	.box-term-MB-1 {
		padding: 10px;
		max-height: 670px;
		overflow: auto;
	}
}

/* :::::::::::::::::::::::::::::::::::::::::::::::: EmpeñosTerminosCondiciones :::::::::::::::::::::::::::::::::::::::::::::::::: */
.title-Terms-Propuestas {
	font-size: 25px;
	font-weight: bold;
	color: #000000;
	line-height: 0px;
}
.subTitle-Terms-Propuestas {
	font-size: 16px;
	color: #000000;
}
