/* FONTS FOUNDIT */
@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
@font-face {
	font-family: Cinzel-Regular;
	src: url(../../Util/fonts/Cinzel/Cinzel-Regular.ttf);
}
/*? SECTION 1 VENTAS MUNDO BARTER */
.VentasSection1MB {
	background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Campa%C3%B1a+Comercial+Barter+Luxe+(Irene)+18.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position:  center;
	height: 90vh;
    display: flex;
}
.VentasSection1MB  .body{
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
}
.VentasSection1MB .body .contentSection1MB{
width: 40%;
}
.VentasSection1MB .body .contentSection2MB{
width: 60%;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0px 0px 10px 32px;
}
.VentasSection1MB .body .contentSection2MB .titlesection2MB{
	color: #000000;
	font-size: 80px;
	line-height: 80px;
	margin: 0 !important;
	font-weight: 400;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
}
.VentasSection1MB .body .contentSection2MB .subtitlesection2MB{
    line-height: 35px;
	font-size: 35px;
	font-weight: 400;
    color: #000000;
	font-family: Baskervville-Regular !important;
    width: 90%;
}
.VentasSection1MB .body .contentSection2MB .subtitlesection2MB .bottomLink{
    display: flex;
}
.VentasSection1MB .body .contentSection2MB  .bottomLink .btnLink{
    /* background-color: #757d6563;
	color: #757d65; */
    background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
    font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	/* border-radius: 100px; */
}
.VentasSection1MB .body .contentSection2MB  .bottomLink .btnLink:hover{
    /* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
    /* background-color: #757d6593; */
    background-color: #252525;;
}

/* cambios pantallas resolución desktop 1366  */
@media (max-width: 1366px) {
    .VentasSection1MB {
        height: 90vh;
    }
       
        .VentasSection1MB .body .contentSection2MB .titlesection2MB{
            font-size: 70px;
            line-height: 70px;
        }
        .VentasSection1MB .body .contentSection2MB .subtitlesection2MB{
            line-height: 30px;
            font-size: 30px;
            margin: 9px 0px;
            width: 90%;
        }
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .VentasSection1MB {
        height: auto;
      }
      .VentasSection1MB  .body{
      gap: 20px;
      }
      .VentasSection1MB .body .contentSection1MB{
      width: 40%;
      }
      .VentasSection1MB .body .contentSection2MB{
      /* padding: 20px 0px; */
      padding: 10px 0px 10px 22px;
      width: 60%;
      }
      .VentasSection1MB .body .contentSection2MB .titlesection2MB{
          font-size: 40px;
          line-height: 40px;
      }
      .VentasSection1MB .body .contentSection2MB .subtitlesection2MB{
          line-height: 30px;
          font-size: 30px;
          margin: 9px 0px;
          width: 90%;
      }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .VentasSection1MB {
        height: auto;
      }
      .VentasSection1MB  .body{
      gap: 20px;
      }
      .VentasSection1MB .body .contentSection1MB{
      width: 40%;
      }
      .VentasSection1MB .body .contentSection2MB{
      /* padding: 20px 0px; */
      padding: 10px 0px 10px 22px;
      width: 60%;
      }
}
@media (max-width: 600px) {
    .VentasSection1MB {
      height: auto;
    }
    .VentasSection1MB  .body{
    gap: 20px;
    }
    .VentasSection1MB .body .contentSection1MB{
    width: 40%;
    }
    .VentasSection1MB .body .contentSection2MB{
        /* padding: 10px 0px; */
        padding: 10px 0px 10px 10px;
    width: 60%;
    }
    .VentasSection1MB .body .contentSection2MB .titlesection2MB{
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }
    .VentasSection1MB .body .contentSection2MB .subtitlesection2MB{
        line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
    }
    
    .VentasSection1MB .body .contentSection2MB  .bottomLink .btnLink{
        border-radius: 4px;
        font-size: 14px;
        background-color: #000000;
    }

}

/*? SECTION 2 VENTAS MUNDO BARTER */
.VentasSection2MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.VentasSection2MB .cardVSection2{
    width: 1300px;
}
.VentasSection2MB .cardVSection2 p{
    font-weight: 400;
    font-size: 50px;
    line-height: 72px;
   color:#BD7439;
   font-family: Baskervville-Regular !important;
   margin: 0;
}
.VentasSection2MB .cardVSection2 .ContainerInstructions{
   display: flex;
   flex-direction: column;
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .body{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50%;
}

 .VentasSection2MB .cardVSection2 .ContainerInstructions .body .number{
    height: 500px;
}
 .VentasSection2MB .cardVSection2 .ContainerInstructions .body .number span{
    font-size: 350px;
    line-height: 420px;
    color: rgba(225, 194, 144, 1);
    font-weight: 400;
    font-family: Cinzel-Regular !important;
    opacity: 30%;
}

.VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight{
display: flex;
/* height: 216px; */
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight2{
display: flex !important;
/* height: 381px !important;  */
height: 250px !important;
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight3{
display: flex !important;
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction{
 display: flex;
 flex-direction: column;
 /* margin: 0px -36px; */
 gap: 15px;
}

.VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .title{
   color: #371F13;
   font-size: 40px;
   line-height: 40px;
   font-weight: 400;
   width: 90%;
   font-family: Baskervville-Regular !important;
  
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .subtitle{
   color: #000;
   text-align: justify;
   font-size: 16px;
   line-height: 19.2px;
   width: 80%;
   font-weight: 500;
   font-family: Rosario-Regular !important;
}
.VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction a{
   color: #000;
   text-align: justify;
   font-size: 16px;
   line-height: 19.2px;
   width: 80%;
   font-weight: 500;
   font-family: Rosario-Regular !important;
   cursor: pointer;
   text-decoration: underline ;
   text-decoration-color: #000;
   text-decoration-thickness: 1.2px;
   -moz-text-decoration-color: #000;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .VentasSection2MB {
        padding: 0px 10px;
    }
    .VentasSection2MB .cardVSection2{
        width: 100%;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction{
     margin: 0px 0px;
    }	
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .title{
        width: 100%;
     }
     .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .subtitle{
        width: 100%;
     }
}/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .VentasSection2MB {
        padding: 0px 10px;
    }
    .VentasSection2MB .cardVSection2{
        width: 100%;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction{
     margin: 0px 0px;
    }	
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .title{
        width: 100%;
     }
     .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .subtitle{
        width: 100%;
     }
}
@media (max-width: 600px) {
    .VentasSection2MB {
        padding: 0px 10px;
    }
    .VentasSection2MB .cardVSection2{
        width: 100%;
    }
    .VentasSection2MB .cardVSection2 p{
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body{
       width: 100%;
    }
    
     .VentasSection2MB .cardVSection2 .ContainerInstructions .body .number{
        height: auto;
    }
     .VentasSection2MB .cardVSection2 .ContainerInstructions .body .number span{
        font-size: 200px;
        line-height: 240px;
    }
    
    .VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight{
    display: flex;
    flex-direction: column;
    height: auto;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight2{
    display: flex !important;
    height: auto !important; 
    flex-direction: column;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .contentHeight3{
    display: flex !important;
    flex-direction: column;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction{
     margin: 0px 0px;
    }
    
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .title{
       font-size: 16px;
       line-height: 19.2px;
       width: 100%;
      
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .subtitle{
       font-size: 12px;
       line-height: 14.4px;
       font-weight: 700;
       width: 100%;
    }
    .VentasSection2MB .cardVSection2 .ContainerInstructions .body .instruction .subtitle a{
       font-size: 12px;
       line-height: 14.4px;
       font-weight: 700;
       width: 100%;
    }
}

/*? SECTION 3 VENTAS MUNDO BARTER */
.VentasSection3MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.VentasSection3MB .cardVSection3{
    width: 1300px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.VentasSection3MB .cardVSection3 .cardSection1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 44px;
}
.VentasSection3MB .cardVSection3 .cardSection1 .Logo{
    width: 30%;
}
.VentasSection3MB .cardVSection3 .cardSection1 .text{
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Baskervville-Regular !important;
}

.VentasSection3MB .cardVSection3 .cardSection1 .bottomLink{
    display: flex;
}
.VentasSection3MB .cardVSection3 .cardSection1 .bottomLink .btnLink{
    background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
    font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	/* border-radius: 100px; */
}
.VentasSection3MB .cardVSection3 .cardSection1 .bottomLink .btnLink:hover{
    /* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
    background-color: #757d6593;
}
.VentasSection3MB .cardVSection3 .cardSection2 {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px;
}
.VentasSection3MB .cardVSection3 .cardSection2 .contentimg img{
   width: 100%;
   border-radius: 4px;
}
.VentasSection3MB .cardVSection3 .cardSection2Movil{
    display: none;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .VentasSection3MB{
       padding: 0px 20px;
    }
    .VentasSection3MB .cardVSection3{
        width: 100%;
    }
    .VentasSection3MB .cardVSection3 .cardSection1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 44px;
    }
    .VentasSection3MB .cardVSection3 .cardSection2 {
        gap: 15px;
     }
    .VentasSection3MB .cardVSection3 .cardSection2 .contentimg img{
       width: 100%;
       border-radius: 4px;
    }
    .VentasSection3MB .cardVSection3 .cardSection2Movil{
        display: none;
    }
   
}/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .VentasSection3MB{
        padding: 0px 20px;
     }
     .VentasSection3MB .cardVSection3{
         width: 100%;
     }
     .VentasSection3MB .cardVSection3 .cardSection1 {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       gap: 44px;
     }
     .VentasSection3MB .cardVSection3 .cardSection2 {
        gap: 15px;
     }
     .VentasSection3MB .cardVSection3 .cardSection2 .contentimg img{
        width: 100%;
     }
     .VentasSection3MB .cardVSection3 .cardSection2Movil{
         display: none;
     }
}
@media (max-width: 600px) {
    
    .VentasSection3MB {
        padding: 0px 20px;
    }
    .VentasSection3MB .cardVSection3{
        width: 100%;
        gap: 30px;
    }
    .VentasSection3MB .cardVSection3 .cardSection1 {
      gap: 20px;
    }
    .VentasSection3MB .cardVSection3 .cardSection1 .Logo{
        width: 50%;
    }
    .VentasSection3MB .cardVSection3 .cardSection1 .text{
        font-size: 16px;
        line-height: 19.2px;
    }
    
    .VentasSection3MB .cardVSection3 .cardSection1 .bottomLink .btnLink{
        font-size: unset;
        border-radius: 4px;
        background-color: #000000;
}
    
    .VentasSection3MB .cardVSection3 .cardSection2 {
    display: none;
       
    }
    .VentasSection3MB .cardVSection3 .cardSection2Movil {
    display: flex;
    flex-direction: column;
    gap: 10px;
    }
    .VentasSection3MB .cardVSection3 .cardSection2Movil .contentimg {
    display: flex;
    gap: 10px;
       
    }
    .VentasSection3MB .cardVSection3 .cardSection2Movil .contentimg img{
    width: 50%;
       
    }
  
   
}









/* SEGUNDO ESTILO TEXTO ENCIMA DE IMAGEN */
.V2entasSection1MB  {
    position: relative;
    display: inline-block;
    text-align: center;
    background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Campa%C3%B1a+Comercial+Barter+Luxe+(Irene)+18.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
    display: flex;
}
.V2entasSection1MB img {
width: 100%;
}
.V2entasSection1MB .texto-encima{
    position: absolute;
    top: 40%;
    left: 14%;
    color: #34382F;
    font-weight: 400;
    font-size: 70px;
    line-height: 70px;
    text-align: start;
    width: 25%;
	margin: 0 !important;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
    transform: translate(-50%, -50%);
}
