@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
/*? SECTION 1 FAQS MUNDO BARTER */
.FaqsSection1MB {
	background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Faq%C2%B4s/DSC01179-2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100vh;
    display: flex;
}
.FaqsSection1MB  .body{
display: flex;
justify-content: center;
align-items: center;
}
.FaqsSection1MB .body .contentSection2MB{
/* width: 50%; */
}
.FaqsSection1MB .body .contentSection1MB{
/* width: 50%; */
display: flex;
flex-direction: column;
justify-content: center;
gap: 55px;
margin-left: 60px;
}
.FaqsSection1MB .body .contentSection1MB .titlesection2MB{
	color: #000000;
	font-size: 80px;
	line-height: 80px;
	margin: 0 !important;
	font-weight: 400;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
}
.FaqsSection1MB .body .contentSection1MB .subtitlesection2MB{
    line-height: 35px;
	font-size: 35px;
	font-weight: 400;
    color: #371F13;
	font-family: Baskervville-Regular !important;
    width: 34%;
    margin: 0;
}
.FaqsSection1MB .body .contentSection1MB .subtitlesection2MB .bottomLink{
    display: flex;
}
.FaqsSection1MB .body .contentSection1MB  .bottomLink .btnLink{
    color: #757d65;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 14px;
	text-transform: none;
	gap: 5px;
	border-radius: 100px;
}
.FaqsSection1MB .body .contentSection1MB  .bottomLink .btnLink:hover{
    background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out;
}

/* cambios pantallas resolución desktop 1366  */
@media (max-width: 1366px) {
    .FaqsSection1MB {
        height: 90vh;
     }
     .FaqsSection1MB .body .contentSection1MB .titlesection2MB{
        font-size: 70px;
        line-height: 70px;
    }
    .FaqsSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 35px;
        font-size: 35px;
    }
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .FaqsSection1MB {
        height: 400px;
        background-position: left;
        display: block;
     }
  
   
     .FaqsSection1MB .body .contentSection1MB{
        margin-top: 10px;
        margin-left: 20px;
     gap: 20px;
     }
     .FaqsSection1MB .body .contentSection1MB .titlesection2MB{
         font-size: 40px;
         line-height: 50px;
         font-weight: 400;
     }
     .FaqsSection1MB .body .contentSection1MB .subtitlesection2MB{
         line-height: 20px;
         font-size: 20px;
         font-weight: 400;
         margin: 9px 0px;
         width: 34%;
     }
  
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .FaqsSection1MB {
        height: 700px;
        background-position: left;
     }
     .FaqsSection1MB .body .contentSection1MB{
        margin-top: 10px;
        margin-left: 20px;
     gap: 40px;
     }
     .FaqsSection1MB .body .contentSection1MB .titlesection2MB{
         font-size: 60px;
         line-height: 50px;
         font-weight: 400;
     }
     .FaqsSection1MB .body .contentSection1MB .subtitlesection2MB{
         line-height: 30px;
         font-size: 30px;
         width: 30%;
     }
}
@media (max-width: 600px) {
    .FaqsSection1MB {
       height: 300px;
       background-position: left;
       display: block;
    }
    .FaqsSection1MB  .body{
    display: flex;
    justify-content: center;
    align-items: center;
    }
  
    .FaqsSection1MB .body .contentSection1MB{
        margin-top: 10px;
        margin-left: 10px;
    gap: 0px;
    }
    .FaqsSection1MB .body .contentSection1MB .titlesection2MB{
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }
    .FaqsSection1MB .body .contentSection1MB .subtitlesection2MB{
        line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        margin: 9px 0px;
        width: 60%;
    }
}

/*? SECTION 2 FAQS MUNDO BARTER */
.FaqsSectionVentas{
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 60px;
}
.FaqsSectionVentas .contentFaqs{
    width: 1300px;
    text-align: center;
}
.FaqsSectionVentas .contentFaqs .titlefqs{
  font-weight: 400;
  font-size: 35px;
  line-height: 40.04px;
  font-family: Baskervville-Regular !important;
}
.FaqsSectionVentas .contentFaqs .accordion {
    /* border: 2px solid #E5E7E0;
    border-radius: 4px; */
 margin-top: 20px;
    /* box-shadow: none; */
}

.FaqsSectionVentas .contentFaqs .accordion .acordionbackground{
	/* background-color: #fff; */
	/* border-top: 0px solid transparent !important; */
}

.FaqsSectionVentas .contentFaqs .accordion .acordionbackground p{
    font-family: Rosario-Regular !important;
    font-weight: 700;
    font-size: 16px;
    line-height:19.39px ;
}
.FaqsSectionVentas .contentFaqs .accordion .acordiondetails{
	background-color: #fff;
	font-family: Baskervville-Regular !important;
    text-align: start;
}
.FaqsSectionVentas .contentFaqs .accordion .acordiondetails p, a{
    font-family: Rosario-Regular !important;
    font-weight: 500;
    font-size: 16px;
    line-height:19.39px ;
}
/* .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    background-color: #757D65 !important;
    color: #fff !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded p {
    color: #fff !important;
} */
.containertxtBottomfqs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.containertxtBottomfqs .cardbottom{
    width: 1300px;
}
.containertxtBottomfqs .cardbottom .txtbottomfqs{
    text-align: center;
    font-family: Baskervville-Regular !important;
    font-size: 30px;
    line-height: 30px;
    font-weight: 400;
}


/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .FaqsSectionVentas{
        padding: 0px 10px;
       }
       .FaqsSectionVentas .contentFaqs{
           width: 100%;
           text-align: center;
       }
       .containertxtBottomfqs{
        padding: 0px 10px;
     }
     .containertxtBottomfqs .cardbottom{
         width: 100%;
     }
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .FaqsSectionVentas{
        padding: 0px 10px;
       }
       .FaqsSectionVentas .contentFaqs{
           width: 100%;
           text-align: center;
       }
       .containertxtBottomfqs{
        padding: 0px 10px;
     }
     .containertxtBottomfqs .cardbottom{
         width: 100%;
     }
}
@media (max-width: 600px) {
    .FaqsSectionVentas{
      padding: 0px 10px;
     }
     .FaqsSectionVentas .contentFaqs{
         width: 100%;
         text-align: center;
     }
     .FaqsSectionVentas .contentFaqs .titlefqs{
       font-size: 20px;
       line-height: 22.88px;
     }
     .FaqsSectionVentas .contentFaqs .accordion {
         border: 2px solid #E5E7E0;
         border-radius: 4px;
      margin-top: 20px;
         box-shadow: none;
     }
     
     
     .FaqsSectionVentas .contentFaqs .accordion .acordionbackground p{
       
         text-align: left;
     }

     .containertxtBottomfqs{
       padding: 0px 10px;
    }
    .containertxtBottomfqs .cardbottom{
        width: 100%;
    }
    .containertxtBottomfqs .cardbottom .txtbottomfqs{
        font-size: 16px;
        line-height: 16px;
    }
    
   
}