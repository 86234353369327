.box-proceso-producto{
    display: flex;
    padding: 0px 20px 0px 10px;
    justify-content:center;
    align-items:center;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box-proceso-producto-list{
    margin-top:30px;
    justify-content:center;
    align-items:center;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-proceso-producto{
    width: 100%;
}

.title-proceso-producto{
    font-size:30px;
    font-weight:bold;
    font-family: 'Fira Sans', sans-serif;
}

.subtitle-proceso-producto{
    font-size:16px;
    font-family: 'Fira Sans', sans-serif;
}

.chipStatus-proceso-producto{

}