.bodyFPVW {
	position: relative !important;
	height: 95vh !important;
	width: 100%;
	/* background-image: url(../Images/logo-barter-sin-fondo.webp); */
	background: linear-gradient(to right, #d7d1c4d8, #d7d1c4d8),
		url('../../Images/Home/slider-3.jpeg') !important;
	background-size: cover !important;
	background-attachment: fixed !important;
	background-position: center !important;
	object-fit: cover !important;
	background-repeat: no-repeat !important;
}
.modal-FPVW {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1278px;
	height: 744px;
	background-color: #fff;
	border-radius: 10px;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.modal-FPVW {
		width: 95%;
		height: auto;
		object-fit: cover;
	}
}
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.modal-FPVW {
		width: 95%;
		height: auto;
	}
}
@media (max-width: 1366px) {
	.modal-FPVW {
		width: 95%;
		height: auto;
	}
}
@media (max-width: 600px) {
	.bodyFPVW {
		height: 100vh !important;
	}
	.modal-FPVW {
		width: 95%;
		height: auto;
	}
}

/*? MODAL MOBILE  */
@media (max-width: 600px) {
	.modal-FPVWMobile {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 95%;
		height: auto;
		background-color: #fff;
		/* border-radius: 10px; */
		width: 100% !important;
		height: 100% !important;
		background: linear-gradient(to right, #ffffffcc, #ffffffcc),
			url('../../Images/Home/Registrer.jpeg') !important;
		background-size: cover !important;
		background-position: center center;
		background-repeat: no-repeat;
		/* border-radius: 10px; */
	}
	.header-logo-FPVWMobile {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.container-FPVWMobile {
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		/* align-items: center; */
		padding: 0px 15px;
	}
}
