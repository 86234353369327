/* background: linear-gradient(45deg, #C58989, #CBB878,#9EB5AE, #D7D1C4) !important; */
.TableCalendarContainer {
	/* background-color: #fff !important; */
	padding: 0 30px !important;
	margin-bottom: 40px;
}

.TableCalendarBody {
	background-color: #fff;
	padding: 0px 0px !important;
	border-radius: 10px !important;
	box-shadow: 5px 5px 19px 1px rgba(0, 0, 0, 0.2);
}

.calendarBtnConfirmar {
	background-color: #c58989 !important;
}

.calendarBtnCancelar {
	background-color: #9eb5ae !important;
}

.btnHoyCalendar {
	background-color: #000 !important;
	color: #fff !important;
	border: 1px solid #545454 !important;
	font-weight: bold !important;
}

.iconArrowCalendar {
	color: #fff !important;
}

.dayActive {
	/* color: #c58989 !important; */
	color: #c58989 !important;
	font-weight: bold !important;
}

/* background: linear-gradient(45deg, #C58989, #CBB878,#9EB5AE, #D7D1C4) !important; */
.btnDisponible {
	border: 1.5px solid #c58989 !important;
	color: #c58989 !important;
	font-weight: 600 !important;
	box-shadow: 3px 3px 10px 1px #c5898967;
	font-family: AvenirLight !important;
	font-size: 16px !important;
	padding: 0px 10px !important;
	/* height: 20px!important;*/
	position: relative !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.CalendarIcon {
	font-size: 20px !important;
	color: #c58989 !important;
}

.BtnRenderMont {
	margin: 0 !important;
	padding: 0 !important;
	color: #d7d1c4 !important;
	font-weight: bold !important;
}

@media (max-width: 600px) {
	.TableCalendarContainer {
		padding: 0 10px !important;
	}

	.tableBody-hours {
		height: 100% !important;
		padding-top: 50px !important;
	}

	.cel-hours {
		height: 100% !important;
	}

	.btnDisponible {
		font-size: 10px !important;
		padding: 1px 1px !important;
		height: 24px !important;
	}

	.CalendarIcon {
		font-size: 0px !important;
		color: #c58989 !important;
	}
}
