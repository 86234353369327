@font-face {
	font-family: CarlaSansLight;
	src: url(../../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
@font-face {
	font-family: Montserrat-Regular;
	src: url(../../../Util/fonts/Montserrat/Montserrat-Regular.ttf);
}
.ContainerFooterMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0px 20px;
	background-color: #F6EFDE;
	margin-top: 60px;
}
.ContainerFooterMobile .ContainerAcordion {
	width: 100% !important;
	margin-top: 30px;
}
.ContainerFooterMobile .ContainerAcordion .accordion {
	border: 0px;
}

.ContainerFooterMobile .ContainerAcordion .accordion .acordionbackground{
	background-color: #F6EFDE;
	border-top: 0px solid transparent !important;
	border-bottom: 2px solid #E5E7E0;
}
.ContainerFooterMobile .ContainerAcordion .accordion .acordiondetails{
	background-color: #F6EFDE;
	font-family: Baskervville-Regular !important;
}
.ContainerFooterMobile .ContainerAcordion .accordion .acordiondetails span {
	/* text-transform: capitalize; */
	font-size: 15px;
	font-weight: 500;
	font-family: Rosario-Regular !important;
}
.ContainerFooterMobile .ContainerAcordion .TitleAcordion {
	text-transform: capitalize;
	font-size: 20px;
	font-weight: 700;
	/* font-family: Montserrat-Regular !important; */
	/* font-family: Rosario-Regular !important; */
	font-family: Baskervville-Regular !important;
}

.ContainerFooterMobile .ContainerBottom {
	margin-top: 15px;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.ContainerFooterMobile .ContainerBottom .sectionbottom1 {
display: flex;
justify-content: space-between;
align-items: center;
}
.ContainerFooterMobile .ContainerBottom .sectionbottom1 img {
width: 30%;
}
.ContainerFooterMobile .ContainerBottom .sectionbottom1 .redesSocialess {
display: flex;
}
.ContainerFooterMobile .ContainerBottom .sectionbottom1 .redesSocialess img{
	height: 35px;
	width: 35px;
}
.ContainerFooterMobile .ContainerBottom .sectionbottom2 {
	display: flex;
	justify-content: end;
	align-items: center;
	}
.ContainerFooterMobile .ContainerBottom .sectionbottom2 span{
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	font-family: Rosario-Regular !important;
	}

