.pay-section1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60%;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 20px;
	padding: 0px 20px 50px 20px;
}

.pay-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
	margin: auto;
	padding: 0px 20px 20px 20px;
}

.pay-section2 {
	display: flex;
	flex-direction: column;
	width: 40%;
	height: fit-content;
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	border-radius: 20px;
	padding: 0px 20px 20px 20px;
}

/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

.section1 {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px 10px 20px;
	width: auto;
	height: 50%;
	/* background-color:rgb(255, 255, 255); */
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.section2 {
	width: 100%;
	height: 40%;
	padding: 10px 20px 10px 20px;
	/* background-color:rgba(0, 0, 0, 0.164); */
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.title-detalle-empeño-pay {
	font-size: 20px;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
}

/* ----------------------------------     CARD PAYMENT ----------------------------------------------- */

.container-Card-Payment {
	width: 550px;
	height: 300px;
	padding-bottom: 10px;
	border-radius: 15px;
	margin: auto;
	background-image: linear-gradient(120deg, #fdfbfb 0%, #e3e8eb 100%);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-number {
	margin-top: 10px;
	line-height: 0px;
	font-size: 25px;
	font-weight: bold;
	font-family: 'Rajdhani', sans-serif;
}

.text-card {
	text-transform: uppercase;
	font-weight: 700;
}

.bold {
	font-weight: bold;
}

.img-logo-payment {
	width: 60px;
	margin-right: 20px;
}

.img-logo-chip {
	width: 50px;
	margin-left: 30px;
}

.icon-card {
	color: #8a7352;
	font-size: 35px !important;
	transform: rotate(90deg);
}

.container-icon-pay {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #b7e4c7;
	margin-right: 20px;
}

.container-footer-pay {
	display: flex;
	justify-content: space-between;
}

.section2 {
	display: block;
}
.titular-data-card {
	font-weight: bold;
}

.date-data-card {
	margin-top: auto;
	font-weight: bold;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.pay-container {
		width: 100%;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.pay-container {
		width: 100%;
	}
}
/* arriba | derecha | abajo | izquierda */
@media (max-width: 600px) {
	.pay-container {
		width: 100%;
		padding: 0px 0px 0px 0px;
	}

	.container-Card-Payment {
		width: 100%;
		height: 220px;
	}

	.section1 {
		padding: 0px 10px 0px 10px;
		width: auto;
		height: fit-content;
	}

	.card-number {
		font-size: 14px;
		letter-spacing: 5px;
	}

	.titular-card {
		font-size: 14px;
	}

	.titular-data-card {
		font-size: 14px;
		font-weight: bold;
		margin-top: 10px;
	}

	.date-data-card {
		margin-top: 0px;
		font-weight: bold;
	}
}
