/* FONTS FOUNDIT */
@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
/*? SECTION 1 FACTURACION MUNDO BARTER */
.FacSection1MB {
	background-image: url('https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Facturaci%C3%B3n/Bolsa.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 90vh;
    display: flex;
}
.FacSection1MB  .body{
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
width: 100%;
}
.FacSection1MB .body .contentSection1MB{
width: 50%;
}
.FacSection1MB .body .contentSection2MB{
width: 50%;
display: flex;
flex-direction: column;
justify-content: center;
}
.FacSection1MB .body .contentSection2MB .titlesection2MB{
	color: #000000;
	font-size: 80px;
	line-height: 80px;
	margin: 0 !important;
	font-weight: 400;
	text-transform: uppercase;
	font-family: Rosario-Regular !important;
}
.FacSection1MB .body .contentSection2MB .subtitlesection2MB{
    line-height: 35px;
	font-size: 35px;
	font-weight: 400;
    color: #371F13;
	font-family: Baskervville-Regular !important;
    width: 90%;
}
/* cambios pantallas resolución desktop 1366  */
@media (max-width: 1366px) {
	.FacSection1MB {
		height: 90vh;
	}
	.FacSection1MB .body .contentSection2MB .titlesection2MB{
		font-size: 70px;
		line-height: 70px;
	}
	.FacSection1MB .body .contentSection2MB .subtitlesection2MB{
	    line-height: 35px;
          font-size: 35px;
		  margin: 20px;
	}
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.FacSection1MB {
		height: auto;
	}
	.FacSection1MB  .body{
	gap: 20px;
	}
	.FacSection1MB .body .contentSection1MB{
	width: 40%;
	}
	.FacSection1MB .body .contentSection2MB{
		padding: 10px 0px;
		width: 60%;
	}
	.FacSection1MB .body .contentSection2MB .titlesection2MB{
		font-size: 40px;
		line-height: 40px;
	}
	.FacSection1MB .body .contentSection2MB .subtitlesection2MB{
	    line-height: 30px;
          font-size: 30px;
          margin: 9px 0px;
          width: 90%;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.FacSection1MB {
		height: auto;
	}
	.FacSection1MB  .body{
	gap: 20px;
	}
	.FacSection1MB .body .contentSection1MB{
	width: 40%;
	}
	.FacSection1MB .body .contentSection2MB{
		padding: 10px 0px;
		width: 60%;
	}
	.FacSection1MB .body .contentSection2MB .titlesection2MB{
		font-size: 40px;
		line-height: 40px;
	}
	.FacSection1MB .body .contentSection2MB .subtitlesection2MB{
	    line-height: 30px;
          font-size: 30px;
          margin: 9px 0px;
          width: 90%;
	}
}
@media (max-width: 600px) {
	.FacSection1MB {
		height: auto;
	}
	.FacSection1MB  .body{
	gap: 20px;
	}
	.FacSection1MB .body .contentSection1MB{
	width: 40%;
	}
	.FacSection1MB .body .contentSection2MB{
		padding: 10px 0px;
		width: 60%;
	}
	.FacSection1MB .body .contentSection2MB .titlesection2MB{
		font-size: 24px;
        line-height: 24px;
        font-weight: 400;
	}
	.FacSection1MB .body .contentSection2MB .subtitlesection2MB{
		line-height: 16px;
        font-size: 16px;
        font-weight: 400;
        margin: 9px 0px;
        width: 90%;
	}
   
}
/*? SECTION 2 FACTURACION MUNDO BARTER */
.FacSection2MB{
    display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.FacSection2MB .cardVSection3{
    width: 1300px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.FacSection2MB .cardVSection3 .cardSection1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 44px;
}
.FacSection2MB .cardVSection3 .cardSection1 .Logo{
    width: 30%;
}
.FacSection2MB .cardVSection3 .cardSection1 .text{
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Baskervville-Regular !important;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.FacSection2MB{
		padding: 0px 20px;
	}
	.FacSection2MB .cardVSection3{
		width: 100%;
	}
	.FacSection2MB .cardVSection3 .cardSection1 {
		gap: 44px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.FacSection2MB{
		padding: 0px 20px;
	}
	.FacSection2MB .cardVSection3{
		width: 100%;
	}
	.FacSection2MB .cardVSection3 .cardSection1 {
		gap: 44px;
	}
}
@media (max-width: 600px) {
	.FacSection2MB{
		padding: 0px 20px;
	}
	.FacSection2MB .cardVSection3{
		width: 100%;
        gap: 30px;
	}
	.FacSection2MB .cardVSection3 .cardSection1 {
		gap: 20px;
	}
	.FacSection2MB .cardVSection3 .cardSection1 .Logo{
		width: 50%;
	}
	.FacSection2MB .cardVSection3 .cardSection1 .text{
		font-size: 16px;
        line-height: 19.2px;
	}
   
}