/***FONDO IMÁGENES***/
.img-banner,
.img-col {
	height: 500px;
}
.img-cat {
	display: flex !important;
	align-items: flex-end;
	align-content: center;
	justify-content: center;
	border-radius: 5px;
	padding-bottom: 13px;
	margin-bottom: 30px;
	height: 500px;
}
.img-slider {
	width: 100%;
	object-fit: cover;
	/* height: 700px; */
	height: auto;
	opacity: 0.9;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	/* .img-slider {
        height: 300px;
    } */
	.img-cat {
		height: 260px !important;
		margin-bottom: 13px;
	}
}
@media (max-width: 600px) {
	.img-col {
		height: 300px;
	}
	.img-cat {
		/* height: 260px !important; */
		margin-bottom: 13px;
	}
}

/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.img-cat {
		height: 300px;
		margin-bottom: 13px;
	}
}
/***FONDO COLORES***/
.fondo-cta {
	background-color: #c58989;
}
.fondo-cta1 {
	background-color: #d7d1c4;
}
.fondo-slider {
	background-color: #000;
	position: relative;
}

/***IMÁGENES***/
.img-banner-consigna {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Consigna/banner-consigna.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-banner-intercambia {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Intercambia/banner-intercambia.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-banner-renta {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Renta/banner-renta.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-banner-empena {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Empena/banner-empena.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-banner-apartado {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Aparta/banner-apartado.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-banner-plan {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Plan/Banner-web.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-col-consigna {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Consigna/RenuevaCloset.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-col-intercambia {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Intercambia/img-intercambia.jpeg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-col-renta {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Renta/img-renta.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-col-empena {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Empena/Prestamos.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.img-col-empena {
		background-position: center;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.img-col-empena {
		background-position: center;
	}
}
.img-col-aparta {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Aparta/img-apartado.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-col-aparta2 {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Aparta/img-apartado2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-col-plan {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Plan/Banner-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-cta {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Consigna/ConsignaSL.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.img-cta {
		background-position: right;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.img-col-empena {
		background-position: center;
	}
	.img-cta {
		background-position: right;
	}
}
.img-cta2 {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Empena/Empena.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-bolsas {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.4), rgba(49, 49, 49, 0.4)), url("../Images/Home/cat-bolsas.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatBolsas.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-zapatos {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.4), rgba(49, 49, 49, 0.4)), url("../Images/Home/cat-zapatos.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatZapatos_mujer.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-acc {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.4), rgba(49, 49, 49, 0.4)), url("../Images/Home/cat-acc.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatAccesorios.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-dis-prem {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.3), rgba(49, 49, 49, 0.3)), url("../Images/Home/cat-disenadores-premium.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatPremium.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-banner-plan {
	background: linear-gradient(
			0deg,
			rgba(93, 93, 93, 0.3),
			rgba(58, 58, 58, 0.3)
		),
		url('../Images/MB-Plan/Banner-mobile.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex !important;
	align-items: flex-end;
	text-align: center;
	justify-content: end;
}
.img-dis-lux {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.3), rgba(49, 49, 49, 0.3)), url("../Images/Home/cat-disenadores-luxury.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatLuxury.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-mujer {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.4), rgba(49, 49, 49, 0.4)), url("../Images/Home/cat-mujer.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatMujer.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-hombre {
	/* background: linear-gradient(0deg, rgba(47, 47, 47, 0.4), rgba(49, 49, 49, 0.4)), url("../Images/Home/cat-hombre.jpeg"); */
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatHombre.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.img-nuevo {
	background: linear-gradient(
			0deg,
			rgba(47, 47, 47, 0.4),
			rgba(49, 49, 49, 0.4)
		),
		url('../Images/MB-Categorias/CatNuevos.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.img-cta-home {
	background: url('../Images/Home/cat-disenadores-premium.jpeg');
	background-repeat: no-repeat;
	background-size: 150%;
	background-position: bottom;
}

/***TITULOS***/
.title-banner {
	margin: 0px;
	color: #fff;
	font-weight: 300;
	letter-spacing: 0.1em;
	font-size: 35px;
	padding-right: 50px;
	padding-bottom: 30px;
}
.title {
	margin: 0px;
	color: #000;
	font-weight: 300;
	font-size: 30px;
}
.title-cta {
	margin: 0px;
	color: #fff;
	font-weight: 500;
	font-size: 40px;
}
.title-cta-hover {
	margin: 0px;
	color: #d7d1c4;
	font-weight: 500;
}
.title-cta-hover:hover {
	color: white;
	cursor: pointer;
}
.title-icons {
	font-weight: 500 !important;
	font-size: 21px;
	margin: 0px;
	padding-bottom: 5px;
}
.title-cat {
	color: white;
	font-weight: 400 !important;
	margin: 0px;
	font-size: 32px;
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.title-cat:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: 0;
	left: 0;
	background-color: white;
	transform: scaleX(0);
	transform-origin: bottom right;
	transition: transform 0.6s ease-out;
}
.title-cat:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
.title-slider {
	color: white;
	font-weight: 500 !important;
	font-size: 40px;
	margin: 0px;
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.title-banner {
		font-size: 40px;
	}
	.title {
		font-size: 25px;
	}
	.title-cta {
		font-size: 30px;
	}
	.title-cat {
		font-size: 25px;
		text-align: center;
	}
	.title-slider {
		font-size: 35px;
		line-height: 40px;
	}
}

@media (max-width: 600px) {
	.title-banner {
		font-size: 20px;
	}
	.title-cat {
		font-size: 18px;
		text-align: center;
	}
	.title-slider {
		font-size: 28px;
	}
}

/***TEXTOS***/
.txt {
	font-weight: 300;
	font-size: 17px;
	line-height: 28px;
}
.txt-cta {
	color: #fff;
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
	margin: 0px;
}
.txt-icons {
	margin: 0px;
	font-size: 14px;
	line-height: 18px;
}
.txt-slider {
	color: white;
	margin: 0px;
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.txt {
		font-size: 15px;
	}
	.txt-cta {
		font-size: 15px;
		line-height: 23px;
	}
}

/***BOTONES***/
.btn-oscuro {
	background-color: #c58989 !important;
	border: none !important;
	padding: 10px 30px !important;
	font-size: 17px !important;
	font-weight: 500 !important;
	margin-top: 30px !important;
	border-radius: 5px !important;
	cursor: pointer !important;
	transition: all 0.2s ease !important;
	color: #fff !important;
}
.btn-oscuro:hover {
	box-shadow: 3px 4px 4px 0px #c58989c4 !important;
	transition: all 0.2s ease !important;
	transform: scale(1.05);
}
.btn-claro {
	/* background-color: #cfb76d !important; */
	background-color: #000000 !important;
	color: #ffffff !important;
	border: none !important;
	padding: 10px 30px !important;
	font-size: 17px !important;
	font-weight: 600 !important;
	margin-top: 30px !important;
	border-radius: 5px !important;
	cursor: pointer !important;
	transition: all 0.2s ease !important;
}

.btn-claro:hover {
	box-shadow: 3px 4px 4px 0px #00000086 !important;
	transition: all 0.2s ease !important;
	transform: scale(1.05);
}

/*RESPONSIVE*/
@media (max-width: 600px) {
	.btn-claro,
	.btn-oscuro {
		font-size: 13px !important;
		/* padding: 10px 10px !important; */
	}
}

/***ANCHOS PERSONALIZADOS***/
.w32-5 {
	width: 32.5%;
}
.w66 {
	width: 66%;
}
.w49-3 {
	width: 49.3%;
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.col-autenticidad .w66 {
		width: 85%;
	}
}

@media (max-width: 600px) {
	.img-bolsas.w32-5,
	.img-zapatos.w32-5,
	.img-mujer.w32-5,
	.img-hombre.w32-5 {
		width: 48.7%;
	}
	.img-acc.w32-5,
	.img-nuevo.w32-5 {
		width: 100%;
	}
	.w49-3 {
		width: 48.7%;
	}
}

/***OTROS***/
.contenido-slider {
	position: absolute;
	top: 68%;
	left: 18%;
	transform: translate(-50%, -50%);
	z-index: 9;
	width: 100%;
}

.contenido-slider2 {
	position: absolute;
	top: 67%;
	left: 57%;
	transform: translate(-50%, -50%);
	z-index: 9;
	width: 100%;
}

@media (max-width: 600px) {
	.contenido-slider {
		/* top: 39%; */
		top: 90%;
		left: 50%;
	}
	.contenido-slider2 {
		/* top: 39%; */
		top: 65%;
		left: 70%;
	}
}

/*RESPONSIVE*/
@media (max-width: 900px) {
	.contenido-slider {
		width: 90%;
	}
	.contenido-slider2 {
		width: 90%;
	}
}

/*RESPONSIVE ID*/
@media (max-width: 900px) {
	#icon-txt-consigna {
		padding-top: 50px;
		padding-bottom: 50px;
		width: 90%;
	}
	#desc-consigna {
		padding-bottom: 50px;
	}
	#contenido-cta {
		margin-left: 30px;
	}
	#servicios-home {
		width: 98%;
	}
	#categorias-home {
		padding-top: 40px;
	}
	#autenticidad-home {
		padding-top: 40px;
	}
	#cta-home {
		padding-top: 40px;
	}
}

@media (max-width: 600px) {
	#desc-consigna .w50,
	#cta-consigna .w50 {
		width: 100%;
	}
	#desc-consigna .title {
		margin-top: 40px;
	}
	#desc-consigna .w80 {
		width: 90%;
	}
	#contenido-cta {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.col-icon {
		flex: 50%;
	}
	.col-margin-resp {
		margin-top: 30px;
	}
	#autenticidad-home {
		padding-top: 10px;
	}
	.col-autenticidad {
		width: 100%;
	}
	.col-autenticidad .w66 {
		margin-bottom: 50px !important;
	}
}

/* ****************** ENCUENTRA LO MEJOR DE TI *********************** */
.ContainerCardOptions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	background-color: #c58989;
	padding: 0px 0px 0px 100px;
}
.imgCardOptions {
	width: 800px;
	height: 500px;
	background: url('../Images/Home/Futter.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	/* position: relative;
    background-attachment: fixed; */
}
@media (max-width: 600px) {
	.ContainerCardOptions {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		background-color: #c48889;
		padding: 0px 0px 0px 0px !important;
		width: auto !important;
		text-align: center;
	}
	.imgCardOptions {
		width: 100% !important;
		height: 300px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.section1CardOptions {
		margin-bottom: 20px;
	}
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.imgCardOptions {
		width: 400px;
	}
	.ContainerCardOptions {
		padding: 0px 0px 0px 40px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.imgCardOptions {
		width: 600px;
	}
}
