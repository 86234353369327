.body-empeño {
    width: 70%;
}

.img-EmpeñoView {
    width: 100%;
}

.head-empeño {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: solid 10px rgba(203, 184, 120);
    background-color: rgb(255, 255, 255);
}

.img-refrendo-Desk {
    display: flex
}




.border-b-empeño {
    border-bottom: solid 1px rgba(0, 0, 0, 0.438);
}

/* ::::::::::::::::::::::::::::::: BOTONES ::::::::::::::::::::::::::::::: */
.btn-empeño-pay {
    width: 400px !important;
    padding: 5px 0px !important;
    font-size: 20px !important;
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 500 !important;
    /* background-color: rgba(27, 67, 50, 1) !important;
    border: solid 1px rgba(216, 243, 220, 1)!important; */
    background-color: rgba(203, 184, 120) !important;
    /* border: solid 1px rgba(216, 243, 220, 1)!important; */
    /* color: #EDE0D4 !important; */
    color: #ffffff !important;
}

.btn-refrendo {
    width: 400px !important;
    padding: 5px 0px !important;
    margin-top: 10px !important;
    font-size: 20px !important;
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 500 !important;
    background-color: rgba(158, 181, 174) !important;
    color: #fff !important;
}

.btn-empeño {
    width: 400px !important;
    padding: 5px 0px !important;
    margin-top: 10px !important;
    font-size: 20px !important;
    font-family: 'Fira Sans', sans-serif !important;
    font-weight: 500 !important;
    background-color: rgba(216, 243, 220, 1) !important;
    color: rgba(27, 67, 50, 1) !important;
}

.body-detalle-empeño {
    width: 70%;

}

.box1-detalle-empeño {
    display: flex;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-top: solid 10px rgba(82, 183, 136, 1);
    background-color: rgb(255, 255, 255);
    margin-bottom: 20px;
    padding: 0px 20px;
}

.box2-detalle-empeño {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    margin-top: 30px;
}

.imgProduct {
    width: 200px;
}

.title-detalle-empeño {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

.sectionTotalEmpeno {
    padding: 20px 0px;
    background-color: rgba(203, 184, 120) !important;
    border-bottom: solid 2px #Ffffff;
    border-radius: 5px;
}

.sectionTotalEmpeno-Title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.sectionTotalEmpeno-SubTitle {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
}


.sectionTotalRefrendo {
    padding: 20px 0px;
    /* background-color: rgba(9, 143, 29,0.5); */
    background-color: rgba(158, 181, 174) !important;
    border-bottom: solid 2px #Ffffff;
    border-radius: 5px;
}


.section1-empeños-pay {
    width: 20%;
}


.section2-empeños-pay {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 10px;
    border-right: solid 1px rgba(0, 0, 0, 0.438);
}

.btn-empeño-pay {
    width: 90% !important;
}

.btn-refrendo {
    width: 90% !important;
}


.section3-empeños-pay {
    width: 30%;
    line-height: 10px;
    padding-left: 20px;
}

.section4-empeños-pay {
    text-align: center;
    padding-top: 30px;
    width: 30%;
    line-height: 10px;
}

.section5-empeños-pay {
    display: none;
}

.box-empeño-vencimiento {
    display: flex;
    flex-direction: column;
}

.dateVencimientoEmpeño {
    font-weight: bold;
    font-size: 25px;
}

.title-Totalpay {
    font-size: 25px;
}

.subTitle-TotalPay {
    margin-top: 25px;
    font-size: 25px;
    font-weight: bold;
}

/* ----------- Section De Desglose ----------- */
.empenoDetailPay {
    margin-top: 30px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box-head-empenoDetailPay {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-empenoDetailPay {
    width: 95%;
    display: flex;
    margin-top: 10px;
    padding: 5px 0px;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
    border-left: solid 3px #40916c;
    border-radius: 10px 0px 0px 10px;
    border-bottom: solid 1px #40916c;

}

.empenoDetailPay-title {
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
}

.empenoDetailPay-Subtitle {
    margin-left: 5px;
    font-size: 16px;
    text-transform: uppercase;
}

/* -:::::::::::::::::::::::::::::::::::::::::       ClienteEmpeñoRefrendar    ::::::::::::::::::::::::::::::::::::::::::::::::::: */


.refrendo-section1-pay {
    width: 30%;
    line-height: 10px;
    padding-left: 30px;
}

.title-ref-pay {
    font-size: 25px;
    font-weight: bold;
}

.subtitle-ref-pay {
    font-size: 20px;
}

.refrendo-img-desk-section2 {
    display: none;
    width: 20%;
}


.refrendo-section3-pay {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-section3-pay {
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
}

.subtitle-section3-pay {
    font-size: 20px;
    font-weight: bold;
}


@media (max-width: 600px) {
    .refrendo-section1-pay {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 10px;

    }

    .title-ref-pay {
        font-size: 20px;
    }

    .subtitle-ref-pay {
        font-size: 16px;
        margin-left: 20px;
    }

    .refrendo-img-desk-section2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .img-EmpeñoView {
        width: 50%;
    }

    .refrendo-section3-pay {
        width: 100%;
        line-height: 0px;
    }

    .title-section3-pay {
        font-size: 20px;
    }

    .subtitle-section3-pay {
        font-size: 16px;
    }
}


        /* cambios tablets css solo para modo Vertical */
        @media (max-width: 900px) {
            .body-empeño {
                width: 100%;
            }
            .section2-empeños-pay {
                text-align: center;
            }
            .sectionTotalEmpeno-Title {
                font-size: 16px;
            }
            .sectionTotalEmpeno-SubTitle {
                font-size: 20px;
                font-weight: bold;
            }
        }
        /* cambios tablets css solo para modo horizontal */
        @media only screen
        and (min-device-width: 641px)
        and (max-device-width: 1180px)
        and (orientation: landscape)
        {
            .body-empeño {
                width: 100%;
            }
            .section2-empeños-pay {
                text-align: center;
            }
            .sectionTotalEmpeno-Title {
                font-size: 16px;
            }
            .sectionTotalEmpeno-SubTitle {
                font-size: 20px;
                font-weight: bold;
            }
        }


/*  :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
@media (max-width: 600px) {
    .body-empeño {
        width: 95%;
    }

    .head-empeño {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        padding: 15px 0px;
    }

    .img-refrendo-Desk {
        display: none;
    }



    .section1-empeños-pay {
        display: none;
    }

    .section2-empeños-pay {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        line-height: 10px;
        border-right: solid 1px rgba(0, 0, 0, 0.438);

    }

    .section3-empeños-pay {
        width: 100%;
        line-height: 10px;
        padding-left: 0px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .sectionTotalEmpeno {
        width: 45%;
        padding: 5px 0px;
        background-color: rgba(203, 184, 120) !important;
        border-bottom: solid 2px #Ffffff;
        border-radius: 0px;
        line-height: 10px;
        border-radius: 10px 0px 0px 10px;
    }

    .sectionTotalEmpeno-Title {
        font-size: 16px;

    }

    .sectionTotalEmpeno-SubTitle {
        font-size: 20px;
        font-weight: bold;
    }

    .sectionTotalRefrendo {
        width: 45%;
        padding: 5px 0px;
        background-color: rgba(158, 181, 174) !important;
        border-bottom: solid 2px #Ffffff;
        border-radius: 0px;
        border-radius: 0px 10px 10px 0px;
    }

    .section4-empeños-pay {
        display: flex;
        width: 100%;
        line-height: 0px;
        padding-top: 0px;
    }

    .section5-empeños-pay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }



    .box-empeño-vencimiento {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .dateVencimientoEmpeño {
        font-weight: bold;
        font-size: 20px;
    }

    .title-Totalpay {
        font-size: 20px;
    }

    .subTitle-TotalPay {
        margin-top: 16px;
        font-size: 20px;
        font-weight: bold;
    }


    /* ----------- Section De Desglose ----------- */

    .empenoDetailPay-title {
        font-size: 12px;
    }

    .empenoDetailPay-Subtitle {
        font-size: 10px;
    }

    .box-empenoDetailPay {
        width: 95%;
        padding-left: 0px;
        padding: 10px 0px;
    }


}