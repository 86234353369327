@font-face {
	font-family: GothamLight;
	src: url(../../Util/fonts/Gotham/GothamLight.ttf);
}
@font-face {
	font-family: AvenirLight;
	src: url(../../Util/fonts/Avenir/Avenir-Light.ttf);
}
@font-face {
	font-family: HelveticaNeue;
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf); */
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf); */
	src: url(../../Util/fonts/Helvetica-Neue-Font/HelveticaNeueLight.ttf);
}
.ContainerEncuesta {
	padding: 0 15%;
	text-align: justify;
	margin-top: 50px;
}
.Container-Content-Encuesta {
	position: relative;
	padding: 2% 5%;
	margin-bottom: 30px;
}
.Container-Content-Encuesta::before {
	content: '';
	/* background-image: url(../../Images/logo-barter-sin-fondo.webp); */
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.1;
	position: absolute;
	background-size: 7%;
}
.Title {
	color: #727272;
	text-transform: uppercase;
	font-family: Helvetica;
	margin-bottom: 0;
}
.Subtitle {
	text-transform: uppercase;
	font-family: Helvetica;
	font-weight: bold;
	margin-top: 10px;
	font-size: 25px;
}

.section-Title-Encuesta {
	font-family: GothamLight !important;
	color: #000 !important;
	font-size: 19px;
}
.section-Title-EncuestaRangos {
	font-family: GothamLight !important;
	color: #000 !important;
	font-size: 13px;
}
.boldEncuesta {
	font-weight: 600;
	font-family: GothamLight !important;
}
.EncuestaGridProbabilidad {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 50px;
}
.BtnProbabilidad {
	color: #000;
	/* background-color: #7272721c; */
	background-color: rgba(0, 0, 0, 0.06);
	padding: 5px 20px 5px 20px;
	/* padding: 5px 10px 5px 10px; */
	font-weight: 600 !important;
	font-size: 25px;
	border-radius: 5px;
	position: relative;
	font-family: GothamLight !important;
}
.BtnProbabilidad:hover {
	cursor: pointer !important;
	background-color: #72727242;
	position: relative;
	transition: all 0.3s ease-in-out;
}
.BtnProbabilidad.active {
	background-color: rgba(0, 0, 0, 0.185);
	border: 2px solid rgba(0, 0, 0, 0.623);
}
.EncuestaBetweenProbabilidad {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.EncuestaBetweenProbabilidad p {
	font-family: GothamLight !important;
}
.EncuestaGridRangos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
}
.BtnsRangos {
	color: #000;
	/* background-color: #7272721c; */
	background-color: rgba(0, 0, 0, 0.06);
	padding: 5px 15px 5px 15px;
	/* padding: 5px 10px 5px 10px; */
	font-weight: 700 !important;
	font-size: 13px;
	border-radius: 5px;
	position: relative;
	font-family: GothamLight !important;
	margin-left: 2px;
}
.BtnsRangos:hover {
	cursor: pointer !important;
	background-color: #72727242;
	position: relative;
	transition: all 0.3s ease-in-out;
}
.BtnsRangos.active {
	background-color: #cbb878;
	border: 0px solid #cbb878;
	color: #fff;
}

.EncuestaBetweenRangos {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.EncuestaBetweenRangos p {
	font-family: GothamLight !important;
}

.flexRadioEncuesta {
	display: flex;
	flex-direction: row;
}
.imgBLencuesta {
	width: 25%;
}
.TitleInterior-Exterior {
	font-weight: 600 !important;
	padding: 2px 0px;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.BtnsRangos {
		font-size: 7px;
		padding: 5px 9px 5px 9px;
	}
	.TitleInterior-Exterior {
		font-size: 14px !important;
	}
}
@media (max-width: 600px) {
	.ContainerEncuesta {
		padding: 0 0%;
		margin-top: 10px;
	}
	.BtnProbabilidad {
		font-size: 15px;
		padding: 5px 10px 5px 10px;
	}

	.BtnsRangos {
		font-size: 9px;
		padding: 5px 7px 5px 7px;
	}
	.section-Title-Encuesta {
		font-size: 15px;
	}
	.section-Title-EncuestaRangos {
		font-size: 10px;
	}
	.boldEncuesta {
		font-size: 15px;
	}
	.flexRadioEncuesta {
		display: flex;
		flex-direction: column;
	}
	.imgBLencuesta {
		width: 50%;
	}
	.EncuestaGridProbabilidad {
		margin-top: 20px;
	}
	.EncuestaGridRangos {
		margin-top: 7px;
	}
	.TitleInterior-Exterior {
		font-size: 14px !important;
	}
}

/*? STYLES MODAL AGRADECIMIENTO  */
.backdropfilter {
	backdrop-filter: blur(10px) !important;
}
.container-modal-Agradecimiento {
	background-color: rgb(255, 255, 255);
	width: 800px;
	height: 590px;
	margin: auto;
	border-radius: 10px;
}
.container-modal-Agradecimiento2 {
	background-color: rgb(255, 255, 255);
	width: 500px;
	height: 200px;
	margin: auto;
	border-radius: 10px;
}
.header-modal-Agradecimiento {
	/* background-color:  #D7D1C4; */
	/*color: #000;
	*/
	height: 60px;
	display: flex;
	padding: 0px 20px 0px 20px;
	align-items: center;
	border-radius: 10px 10px 0px 0px;
}
.text-TitleAgradecimiento {
	font-weight: 600;
	color: '#545454';
	text-transform: uppercase;
}
.container-body-modal-Agradecimiento {
	/* padding: 10px 10px 0px 10px; */
	padding: 0px 40px 0px 40px;
	text-align: center;
}
/* background: linear-gradient(45deg, #C58989, #CBB878,#9EB5AE, #D7D1C4) !important; */
@media (max-width: 600px) {
	.container-modal-Agradecimiento {
		width: 95%;
		height: 630px;
	}
	.container-body-modal-Agradecimiento {
		padding: 0px 10px 0px 10px;
		text-align: center;
	}
}
