.alert-info {
    padding: 10px 30px !important;
    border-radius: 10px !important;
    background-color: #ebfafd !important;
    color: #0077b6 !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    box-shadow: rgba(158, 158, 158, 0.25) 0px 54px 55px, rgba(252, 252, 252, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px !important;
    border-left: solid 5px #0077b6;
}

.alert-success {
    padding: 10px 30px !important;
    border-radius: 10px !important;
    background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    box-shadow: rgba(158, 158, 158, 0.25) 0px 54px 55px, rgba(252, 252, 252, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px !important;
    border-left: solid 5px #b4f5f4;
}

.alert-Warning {
    padding: 10px 30px !important;
    border-radius: 10px !important;
    border: solid 1px #f0808033;
    background-image: linear-gradient(-225deg, #ffd8d7 0%, #fff2f2 100%);
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-left: solid 5px #ff7a75;
}

@media (max-width: 600px) {
    .alert-info {
        padding: 10px 10px !important;
        border-radius: 10px !important;
    }

    .alert-Warning {
        padding: 10px 10px !important;
        border-radius: 10px !important;

    }

}