.title-card-content {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.btn-abonar-apartado {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    /* background-color: rgba(226, 234, 252, 1) !important;
    color: rgba(27, 67, 50, 1) !important; */
    background-color: #9EB5AE !important;
    color: #FFF !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding: 10px 0px !important;
    font-weight: 600 !important;
}

.btn-reagendar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    background-color: rgba(216, 243, 220, 1) !important;
    color: rgba(1, 79, 134, 1) !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding: 10px 0px !important;
}

.btn-aceptar-propuesta {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    background-color: rgba(216, 243, 220, 1) !important;
    color: rgba(27, 67, 50, 1) !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding: 10px 0px !important;
}

.btn-rechazar-propuesta {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px !important;
    background-color: rgb(243, 216, 216) !important;
    color: rgb(67, 27, 27) !important;
    border-radius: 5px !important;
    width: 100% !important;
    padding: 10px 0px !important;
}

.card-media-box {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 240px;
    border-radius: 10px !important;
}

.card-media-box1 {
    top: 20px;
    width: 50%;
    height: 50px;
    padding: 0px 10px;
    position: absolute;
    text-align: center;
    background-Color: rgba(255, 255, 255, 0.39);
    border: 1px solid white;
    backdrop-filter: blur(20px);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.card-media-box-citas-1 {
    padding: 2px 15px;
    border-radius: 5px;
    text-align: center;
    color: rgba(254, 127, 45, 1);
    background-Color: rgba(249, 229, 216, 0.5);
    border: 1px solid rgba(254, 127, 45, 1);
}

.card-media-box-citas-2 {
    padding: 2px 15px;
    border-radius: 5px;
    text-align: center;
    color: rgba(64, 145, 108, 1);
    background-Color: rgba(216, 243, 220, 0.5);
    border: 1px solid rgba(64, 145, 108, 1);

}

.card-media-box-citas-3 {
    padding: 2px 15px;
    border-radius: 5px;
    text-align: center;
    color: rgba(239, 99, 81, 1);
    background-Color: rgba(251, 195, 188, 0.5);
    border: 1px solid rgba(239, 99, 81, 1);
}
@media (max-width: 600px) {
    .card-media-box-citas-1 {
        padding: 1px 8px;
        border-radius: 5px;
}
    .card-media-box-citas-2 {
        padding: 1px 8px;
        border-radius: 5px;
}
    .card-media-box-citas-3 {
        padding: 1px 8px;
        border-radius: 5px;
}
}


.card-media-box2 {
    /*width: 100%;
    height: 350px;
    object-fit:;
    cover*/
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    margin-top: 40px;

}

.alert-no-data {
    padding: 10px 30px !important;
    border-radius: 50px !important;
    border: solid 1px rgba(66, 73, 73, 0.5);
    background-image: linear-gradient(-225deg, #ECF0F1 0%, #D0D3D4 100%);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (max-width: 600px) {
    .alert-no-data {
        padding: 0px 0px !important;
        border-radius: 10px !important;
    }

}

.alert-icon-no-data {
    color: rgba(66, 73, 73, 0.5);
    font-size: 30px !important;
}