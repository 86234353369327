@font-face {
	font-family: HelveticaNeue;
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf); */
	/* src: url(../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf); */
	src: url(../../Util/fonts/Helvetica-Neue-Font/HelveticaNeueLight.ttf);
}

@font-face {
	font-family: GothamLight;
	src: url(../../Util/fonts/Gotham/GothamLight.ttf);
}

@font-face {
	font-family: AvenirLight;
	src: url(../../Util/fonts/Avenir/Avenir-Light.ttf);
}
@font-face {
	font-family: HelveticaNeueMedium;
	/* src: url(../../Util/fonts/Helvetica-Neue-Font/HelveticaNeue.ttf); */
	src: url(../../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf);
}

/* FONTS FOUNDIT */
@font-face {
	font-family: CarlaSansLight;
	src: url(../../Util/fonts/CarlaSansRegular/CarlaSansLight.ttf);
}
@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
.bold {
	font-weight: bold;
}
/*? SECTION 1 HOME MUNDO BARTER */
.ContainerSection1MB {
	background-image: url(https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/banner_principal.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	/* height: 100vh; */
	height: 85vh;
	width: 100%;
	/* background-size: 100% 100%; */
	display: flex;
}
.ContainerSection1MB .contentSection1MB {
	padding: 55px 0px 60px 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}
.ContainerSection1MB .titlesection1MB {
	/* color: #757d65;
	font-size: 200px;
	line-height: 176px;
	margin: 0 !important;
	text-transform: uppercase;
	font-weight: 300;
	font-family: CarlaSansLight !important; */
	color: #000000;
    font-family: CarlaSansLight !important;
    font-size: 157px;
    font-weight: 300;
    line-height: 130px;
    margin: 0 !important;
    text-transform: uppercase;
}
.ContainerSection1MB .subtitlesection1MB {
	line-height: 40px;
	font-size: 40px;
	width: 54%;
	font-weight: 400;
	font-family: Baskervville-Regular !important;
}


/* cambios pantallas resolución desktop 1366  */
@media (max-width: 1366px) {
	.ContainerSection1MB {
		height: 90vh;
	}
	.ContainerSection1MB .contentSection1MB {
		padding: 55px 0px 60px 70px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
	}
	.ContainerSection1MB .titlesection1MB {
        font-size: 140px;
        line-height: 108px;
	}
	.ContainerSection1MB .subtitlesection1MB {
		line-height: 30px;
		font-size: 30px;
	}
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection1MB {
		height: auto;
	}
	.ContainerSection1MB .contentSection1MB {
		padding: 10px 0px 10px 30px;
	}
	.ContainerSection1MB .titlesection1MB {
		font-size: 120px;
		line-height: 96.8px;
	}
	.ContainerSection1MB .subtitlesection1MB {
		line-height: 30px;
		font-size: 25px;
		width: 60%;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.ContainerSection1MB {
		height: auto;
	}
	.ContainerSection1MB .contentSection1MB {
		padding: 10px 0px 10px 30px;
	}
	.ContainerSection1MB .titlesection1MB {
		font-size: 160px;
		line-height: 140.8px;
	}
	.ContainerSection1MB .subtitlesection1MB {
		line-height: 35px;
		font-size: 35px;
		width: 60%;
	}
}
@media (max-width: 600px) {
	.ContainerSection1MB {
		height: auto;
	}
	.ContainerSection1MB .contentSection1MB {
		padding: 10px 0px 10px 18px;
	}
	.ContainerSection1MB .titlesection1MB {
		font-size: 40px;
		line-height: 40px;
	}
	.ContainerSection1MB .subtitlesection1MB {
		line-height: 16px;
		font-size: 16px;
		width: 60%;
	}
}

/*? SECTION 2 HOME MUNDO BARTER */
.ContainerSection2MB {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 51px;
}
.ContainerSection2MB .title {
	font-weight: 400;
	font-size: 50px;
	font-family: Baskervville-Regular !important;
	color: #bd7439;
	line-height: 72px;
}
.ContainerSection2MB .ContentCards {
	display: flex;
	justify-content: center !important;
	gap: 50px;
	margin-top: 50px;
}
.ContainerSection2MB .ContentCards .ItemCard {
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: justify;
	gap: 30px;
}
.ContainerSection2MB .ContentCards .ItemCard .containerItemCard {
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: 500px;
}
.ContainerSection2MB .ContentCards .ItemCard .contentImg {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;
}
.ContainerSection2MB .ContentCards .ItemCard .contentImg img {
	width: 300px;
	border-radius: 4px;
}

.ContainerSection2MB .ContentCards .ItemCard .contentImg p {
	font-weight: 400;
	font-size: 35px;
	color: #371f13;
	line-height: 48px;
	margin: 0px 0px 0px 0px;
	font-family: Baskervville-Regular !important;
}

.ContainerSection2MB .ContentCards .ItemCard span {
	color: #000;
	font-size: 16px;
	line-height: 19.2px;
	font-family: Rosario-Regular !important;
}
.ContainerSection2MB .ContentCards .ItemCard span .bold {
	font-weight: 600;
	line-height: 19.2px;
}
.ContainerSection2MB .ContentCards .ItemCard .bottomLink {
	display: flex;
	justify-content: end;
}
.ContainerSection2MB .ContentCards .ItemCard .bottomLink .btnLink {
	background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	/* border-radius: 100px; */
}
.ContainerSection2MB .ContentCards .ItemCard .bottomLink .btnLink:hover {
	/* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
	background-color: #252525;
}
.ContainerSection2MBMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 22px;
}
.ContainerSection2MBMobile .title {
	font-weight: 400;
	font-size: 20px;
	font-family: Baskervville-Regular !important;
	color: #bd7439;
	line-height: 24px;
}
.ContainerSection2MBMobile .ContentCards {
display: flex;
flex-direction: column;
justify-content: center !important;
margin-top: 35px;
padding: 0px 10px;
gap: 20px;
}
.ContainerSection2MBMobile .ContentCards .ItemCard {
	width: 100%;
	display: flex;
	/* text-align: justify; */
	gap: 20px;
}
.ContainerSection2MBMobile .ContentCards .ItemCard img {
	width: 40%;
	object-fit: cover;
	border-radius: 4px;
}
.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}
.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody .description p{
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	color: #371f13;
	line-height: 19.2px;
	font-family: Baskervville-Regular !important;
}
.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody .description span{
	color: #000;
	font-weight: 500;
	font-size: 12px;
	line-height: 14.4px;
	font-family: Rosario-Regular !important;
}

.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody .bottomLink {
	display: flex;
	justify-content: start;
}
.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody .bottomLink .btnLink {
	background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 13px;
	line-height: 20px;
	text-transform: none;
	gap: 5px;
	width: 85%;
}
.ContainerSection2MBMobile .ContentCards .ItemCard .sectionBody .bottomLink .btnLink  img{
	width: 10px;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection2MB {
		margin-top: 35px;
	}
	.ContainerSection2MB .ContentCards {
		gap: 25px;
		padding: 0px 50px;
	}
	.ContainerSection2MB .ContentCards .ItemCard {
		width: 100%;
		gap: 20px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .containerItemCard {
		gap: 30px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .contentImg {
		gap: 10px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .contentImg img {
		width: 100%;
		border-radius: 4px;
	}

	.ContainerSection2MB .ContentCards .ItemCard .contentImg p {
		font-size: 35px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.ContainerSection2MB {
		margin-top: 35px;
	}
	.ContainerSection2MB .ContentCards {
		gap: 25px;
		padding: 0px 50px;
	}
	.ContainerSection2MB .ContentCards .ItemCard {
		width: 100%;
		gap: 20px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .containerItemCard {
		gap: 30px;
		height: 570px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .contentImg {
		gap: 10px;
	}
	.ContainerSection2MB .ContentCards .ItemCard .contentImg img {
		width: 100%;
		border-radius: 4px;
	}

	.ContainerSection2MB .ContentCards .ItemCard .contentImg p {
		font-size: 35px;
	}
}
@media (max-width: 600px) {
}

/*? SECTION 3 HOME MUNDO BARTER */
.ContainerSection3MB {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	
}
.ContainerSection3MB .CardSection3MB {
    display: flex;
	gap: 43px;
    width: 1300px;
}
.ContainerSection3MB .CardSection3MB .section1-3MB {
	width: 30%;
}
.ContainerSection3MB .CardSection3MB .section1-3MB img {
	width: 100%;
	border-radius: 4px;
}
.ContainerSection3MB .CardSection3MB .section2-3MB {
	width: 80%;
}
.ContainerSection3MB .CardSection3MB .section2-3MB p  {
	margin: 0;
	font-weight: 400;
	font-size: 50px;
	color: #BD7439;
	line-height: 72px;
	margin-bottom: 15px;
	font-family: Baskervville-Regular !important;
}
.ContainerSection3MB .CardSection3MB .section2-3MB span  {
	font-weight: 400;
	font-size: 26px;
	color: #000000;
	line-height: 35px;
	font-family: Baskervville-Regular !important;
}

.ContainerSection3MB .CardTableCalcula {
	margin-top: 30px;
	width: 100%;
}
.ContainerSection3MB .CardTableCalcula .headStyletxt {
	font-family: Rosario-Regular !important;
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection3MB{
		padding: 0px 20px;
	}
	.ContainerSection3MB .CardSection3MB {
		width: 100%;
	}
	.ContainerSection3MB .CardSection3MB .section2-3MB p  {
		line-height: 60px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.ContainerSection3MB{
		padding: 0px 20px;
	}
	.ContainerSection3MB .CardSection3MB {
		width: 100%;
	}
	.ContainerSection3MB .CardSection3MB .section2-3MB p  {
		line-height: 60px;
	}
}
@media (max-width: 600px) {
	.ContainerSection3MB {
		display: flex;
		flex-direction: column;
	}
	.ContainerSection3MB .CardSection3MB {
		gap: 15px;
	}
	.ContainerSection3MB  p  {
		margin: 0;
		font-weight: 400;
		font-size: 20px;
		color: #BD7439;
		line-height: 24px;
		margin-bottom: 36px;
		font-family: Baskervville-Regular !important;
	}
	.ContainerSection3MB .CardSection3MB .section2-3MB span  {
		font-weight: 500;
		font-size: 12px;
		line-height: 14.4px;
		font-family: Rosario-Regular !important;
	}
}

/*? SECTION 4 HOME MUNDO BARTER */
.ContainerSection4MB {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
}
.ContainerSection4MB .cardSecttion4MB{
    width: 1300px;
}
.ContainerSection4MB .cardSecttion4MB p{
	margin: 0;
	font-weight: 400;
	font-size: 50px;
	color: #BD7439;
	line-height: 72px;
	margin-bottom: 15px;
	font-family: Baskervville-Regular !important;
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas span{
	font-weight: 400;
	font-size: 35px;
	line-height: 40px;
	color: #371F13;
	font-family: Baskervville-Regular !important;
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos{
	overflow: hidden;
	padding: 20px 0px;
	background: #fff;
	white-space: nowrap;
	position: relative;
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos:before, .logos:after{
	position: absolute;
	width: 250px;
	height: 100%;
	content: "";
z-index: 2;
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos:before{
left: 0;
background: linear-gradient(to left,rgba(255,255,255, 0), white);
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos:after{
right: 0;
background: linear-gradient(to right,rgba(255,255,255, 0), white);
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide{
	display: inline-block;
	animation: 50s slide2 infinite linear;
	-webkit-animation: 50s slide2 infinite linear;
}

.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide img{
	height: 50px;
	 margin: 0 10px;
	 background-color: #FBF8F1;
	 padding: 10px;
	 border-radius: 10px;
}
.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide-premium{
	display: inline-block;
	animation: 22s slide infinite linear;
	-webkit-animation: 22s slide infinite linear;
}

.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide-premium img{
	height: 50px;
	 margin: 0 10px;
	 background-color: #FBF8F1;
	 padding: 10px;
	 border-radius: 10px;
}

@keyframes slide {
	from{
		transform: translateX(0);
}to{
	transform: translateX(-100%);
}
}
@keyframes slide2 {
	from{
		transform: translateX(-100%);
}to{
	transform: translateX(0);
}
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection4MB {
        padding: 0px 20px;
	}
	.ContainerSection4MB .cardSecttion4MB{
		width: 100%;
	}
	.ContainerSection4MB .cardSecttion4MB p{
		
		line-height: 60px;
	}
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.ContainerSection4MB {
        padding: 0px 20px;
	}
	.ContainerSection4MB .cardSecttion4MB{
		width: 100%;
	}
	.ContainerSection4MB .cardSecttion4MB p{
		
		line-height: 60px;
	}
}
@media (max-width: 600px) {
	.ContainerSection4MB {
        padding: 0px 20px;
	}
	.ContainerSection4MB .cardSecttion4MB{
		width: 100%;
	}
	.ContainerSection4MB .cardSecttion4MB p{
		font-size: 20px;
		line-height: 24px;
	}
	.ContainerSection4MB .cardSecttion4MB .ContentMarcas span{
	
		font-size: 16px;
		line-height: 19.2px;
	}
	.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos{
		overflow: hidden;
		padding: 20px 0px;
		background: #fff;
		white-space: nowrap;
		position: relative;
	}
	.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos:before, .logos:after{
		width: 50px;
	}
	.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide img{
		 height: 30px;
		 margin: 0 10px;
		 padding: 5px;
		 border-radius: 5px;
}
	.ContainerSection4MB .cardSecttion4MB .ContentMarcas .logos-slide-premium img{
		height: 30px;
		 margin: 0 10px;
		 padding: 5px;
		 border-radius: 5px;
	}
	
}
/*? SECTION 5 HOME MUNDO BARTER */
.ContainerSection5MB {
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.ContainerSection5MB img{
width: 20%;
}
.ContainerSection5MB .cardSecttion5MB{
	margin-top: 150px;
	margin-bottom: 114px;
    width: 1300px;
	display: flex;
	justify-content: center;
	align-items: center;

}
.ContainerSection5MB .cardSecttion5MB .reveal{
	width: 20%;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1{
	display: flex;
	flex-direction: column;
	justify-content: end;
	/* width: 20%; */
	width: 100%;
	gap: 25px;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1 span {
font-size: 35px;
font-weight: 400;
line-height: 40px;
text-align: right;
font-family: Baskervville-Regular !important;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1 .bottomLink{
	display: flex;
	justify-content: end;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1 .bottomLink .btnLink{
	background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	width: 50%;
    /* padding: 0;
    margin: 0; */
	font-family: Rosario-Regular !important;
	/* border-radius: 100px; */

}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1 .bottomLink .btnLink:hover{
	/* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
	background-color: #252525;

}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-1 .bottomLink .btnLink img{
width: 12px;
}

.ContainerSection5MB .cardSecttion5MB .section5MB-2 .contentImgs{
	position: relative;
	display: inline-block;
	text-align: center;
	width: 500px;

}
.ContainerSection5MB .cardSecttion5MB .section5MB-2 .imgbolso{
	border-radius: 4px;
	width: 41%;
	/* height: 265px; */
	position: absolute;
	top: -182px;
	left: 12%;
}
.ContainerSection5MB .cardSecttion5MB .section5MB-2 .imgmarco{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width: 50%;
}
.ContainerSection5MB .cardSecttion5MB .section5MB-2 .imgpersona{
	border-radius: 4px;
	width: 41%;
    /* height: 216px; */
    position: absolute;
    top: -31px;
    left: 49%;
 
}

.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3{
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: start;
	/* width: 20%; */
	width: 100%;
	gap: 25px;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3 span {
font-size: 35px;
font-weight: 400;
line-height: 40px;
text-align: left;
font-family: Baskervville-Regular !important;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3 .bottomLink{
	display: flex;
	justify-content: end;
}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3 .bottomLink .btnLink{
	background-color: #000000;
	color: #ffffff;
	font-weight: 500;
	font-family: Rosario-Regular !important;
	font-size: 20px;
	line-height: 25px;
	text-transform: none;
	gap: 5px;
	width: 100%;
    /* padding: 0; */
    /* margin: 0; */
	font-family: Rosario-Regular !important;
	/* border-radius: 100px; */

}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3 .bottomLink .btnLink:hover{
	/* background-color: #757d6563;
    border-radius: 4px;
    transition: all .1s ease-in-out; */
	background-color: #252525;

}
.ContainerSection5MB .cardSecttion5MB .reveal .section5MB-3 .bottomLink .btnLink img{
width: 12px;
}

.ContainerSection5MBMobile{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	width: 100%;
	
}
.ContainerSection5MBMobile img{
	width: 40%;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile{

	margin-top: 28px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 0px 20px;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1{
	display: flex;
	justify-content: center;
	align-items: center;
    gap: 40px;
	
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentBody{
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 23px;

}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentBody span{
	color: #371F13;
	font-size: 20px;
	line-height: 19.2px;
	font-weight: 400;
	font-family: Baskervville-Regular !important;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentBody .btnLink{
    background-color: #000000;
    color: #ffffff;
    font-weight: 500;
    font-family: Rosario-Regular !important;
    /* font-size: 20px; */
    line-height: 20px;
    text-transform: none;
    gap: 5px;
    width: 85%;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentBody .btnLink img{
   width: 12px;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentImg{
width: 50%;
}
.ContainerSection5MBMobile .cardSecttion5MBMobile .section5MBMobile-1 .contentImg img{
	width: 100%;
	border-radius: 4px;
}
/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection5MB {
		margin-top: 60px;
		padding: 0px 20px;
	}
	.ContainerSection5MB img{
	width: 40%;
	}
	.ContainerSection5MB .cardSecttion5MB{
		margin-top: 100px;
		width: 100%;
	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-1{
		width: 20%;
		gap: 25px;
	}
	

	.ContainerSection5MB .cardSecttion5MB .section5MB-1 .bottomLink .btnLink{
		font-size: 20px;	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-1 .bottomLink .btnLink img{
	width: 20px;
	}
	
	.ContainerSection5MB .cardSecttion5MB .section5MB-2 .contentImgs{
		width: 450px;
	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3{
		width: 20%;
		gap: 25px;
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3 .bottomLink .btnLink{
		font-size: 20px;
	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3 .bottomLink .btnLink img{
	width: 20px;
	}
	
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	
	.ContainerSection5MB img{
	width: 30%;
	}
	.ContainerSection5MB .cardSecttion5MB{
		width: 100%;
	}
	
	.ContainerSection5MB .cardSecttion5MB .section5MB-1 .bottomLink .btnLink{
		font-size: 20px;	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-1 .bottomLink .btnLink img{
	width: 20px;
	}
	
	.ContainerSection5MB .cardSecttion5MB .section5MB-2 .contentImgs{
		width: 500px;
	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3{
		width: 20%;
		gap: 25px;
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3 .bottomLink .btnLink{
		font-size: 20px;
	
	}
	.ContainerSection5MB .cardSecttion5MB .section5MB-3 .bottomLink .btnLink img{
	width: 20px;
	}
}
@media (max-width: 600px) {

}

/*? SECTION 6 HOME MUNDO BARTER */
.ContainerSection6MB {
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.ContainerSection6MB .cardSecttion6MB{
    width: 1300px;
	/* background-color: #eaeaea; */
	display: flex;
	gap: 85px;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1{
	width: 45%;
	width: 45%;
    display: flex;
    flex-direction: column;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 p{
	margin: 0;
    font-weight: 400;
    font-size: 50px;
    color: #BD7439;
    line-height: 72px;
    /* margin-bottom: 15px; */
    font-family: Baskervville-Regular !important;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones{
	display: flex;
	background-color: #FBF8F1;
	width: 500px;
	margin-bottom: 24px;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .self{
	display: flex;
	align-self: end;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody{
padding: 16px;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 10px;
width: 100%;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct{
	display: flex;
	align-items: center;
	gap: 5px;
	width: 50%;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct span{
font-size: 20px;
font-weight: 400;
line-height: 22.88px;
font-family: Baskervville-Regular !important;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct img{
	width: 10%;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody  .opinion{
	font-size: 14px;
	font-weight: 400;
	font-family: Rosario-Regular !important;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody  .nombreOpinion{
	font-size: 12px;
	font-weight: 800;
	font-family: Rosario-Regular !important;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones img{
	width: 25%;
}
.ContainerSection6MB .cardSecttion6MB .section6MB-2{
	width: 55%;
	/* display: flex; */
}
.ContainerSection6MB .cardSecttion6MB .section6MB-2 img{
	/* padding: 60px 0px 0px 0px; */
	width: 100%;
	/* object-fit: contain; */

	
}

/* cambios tablets css solo para modo Vertical */
@media (max-width: 900px) {
	.ContainerSection6MB {
		margin-top: 40px;
		padding: 0px 20px;
	}
	.ContainerSection6MB .cardSecttion6MB{
		width: 100%;
		gap: 20px;
	}

	.ContainerSection6MB .cardSecttion6MB .section6MB-1 p{
		line-height: 60px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones{	
		width: 100%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct{
		width: 80%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct span{
	font-size: 16px;
	line-height: 18.3px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody  .opinion{
		font-size: 12px;
	}
	
	
}
/* cambios tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
	.ContainerSection6MB {
		margin-top: 40px;
		padding: 0px 20px;
	}
	.ContainerSection6MB .cardSecttion6MB{
		width: 100%;
		gap: 20px;
	}

	.ContainerSection6MB .cardSecttion6MB .section6MB-1 p{
		line-height: 60px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones{	
		width: 100%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct{
		width: 80%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct span{
	font-size: 16px;
	line-height: 18.3px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody  .opinion{
		font-size: 12px;
	}
	
	
}
@media (max-width: 600px) {
	.ContainerSection6MB {
		margin-top: 40px;
		padding: 0px 20px;
	}
	.ContainerSection6MB .cardSecttion6MB{
		width: 100%;
		flex-direction: column;
		gap: 20px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1{
		width: 100%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 p{
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 24px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones{	
		width: 100%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct{
		width: 80%;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody .HeadBody .nombreProduct span{
	font-size: 16px;
	line-height: 18.3px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-1 .cardOpinones .optionesBody  .opinion{
		font-size: 12px;
	}
	.ContainerSection6MB .cardSecttion6MB .section6MB-2{
		width: 100%;
	}
}

