@font-face {
	font-family: HelveticaNeueMedium;
	
	src: url(../../Util/fonts/Helvetica-Neue-Font/HelveticaNeue-Medium.ttf);
}

@font-face {
	font-family: Baskervville-Regular;
	src: url(../../Util/fonts/Baskervville/Baskervville-Regular.ttf);
}
@font-face {
	font-family: Rosario-Regular;
	src: url(../../Util/fonts/Rosario/Rosario-Regular.ttf);
}
.containerHeaderMB{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px 20px 0px;
    width: 100%;
}
.containerHeaderMB .section1Mb {
   width: 30%;
}
.containerHeaderMB .section1Mb img{
   width: 40%;
}
.containerHeaderMB .section2Mb{
   display: flex;
   gap: 40px;
}

.containerHeaderMB .section2Mb .BtnHeaderMb {
    /*letter-spacing: 1.7px;
    */line-height: 20px;
    font-weight: 500 !important;
    position: relative;
    display: inline-block;
    /* color: #757D65; */
    color: #000000;
    text-transform: none;
    font-size: 20px;
    border-radius: 100px;
    font-family:  Rosario-Regular !important;
}
.containerHeaderMB .section2Mb .BtnHeaderMb:hover {
    /* background-color: #757d6563; */
    background-color: #000000;
    color: #ffffff;
    border-radius: 4px;
    transition: all .1s ease-in-out;
}
/* .containerHeaderMB .section2Mb .BtnHeaderMb::after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: '';
	display: block;
	height: 1px;
	left: 50%;
	position: absolute;
	background: rgb(183, 183, 183);
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}
.containerHeaderMB .section2Mb .BtnHeaderMb:hover::after {
	width: 100%;
	left: 0;
} */
.containerHeaderMB .section2Mb .BtnHeaderMb2 {
    line-height: 20px;
	font-weight: 500 !important;
	position: relative;
    font-size: 20px;
	display: inline-block;
    color: #fff;
    text-transform: none;
    background-color: #000000;
    font-family:  Rosario-Regular !important;
}
.containerHeaderMB .section2Mb .BtnHeaderMb2:hover {
    background-color: #252525;
}
.containerHeaderMBMobile{
    display: none;
}

/* media Query tablets css solo para modo Vertical */
@media (max-width: 900px) {
    .containerHeaderMB .section1Mb{
        width: 20%;
     }
    .containerHeaderMB .section1Mb img{
        width: 75%;
     }
}
/* media Query tablets css solo para modo horizontal */
@media only screen and (min-device-width: 641px) and (max-device-width: 1180px) and (orientation: landscape) {
    .containerHeaderMB .section1Mb img{
        width: 30%;
     }
}
/* media Query celular */
@media (max-width: 600px) {
	.containerHeaderMB{
        display: none;
    }
    .containerHeaderMBMobile{
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 5px 20px 5px;

    }
    .containerHeaderMBMobile img{
       width: 40%;
    }
    .containerHeaderMBMobile .BtnHeaderMb2{
        line-height: 20px;
        font-weight: 500 !important;
        position: relative;
        display: inline-block;
        color: #fff;
        text-transform: none;
        background-color: #000000;
    }
    .ContentImgDrawer{
        background-color: #F6EFDE;
    }
    .ContentImgDrawer img{
       width: 60%;
       display: block;
       margin: 0 auto;
       padding: 20px 20px;
    }
    .dividerDraw{
        width: 90%;
        height: 0px;
        color: #000;
        background-color: #000;
    }
}