.ContainerTerms {
  padding: 0 15%;
  text-align: justify;
}
.Container-Content-Terms {
  position: relative;
  padding: 2% 5%;
  margin-bottom: 30px;
}
.Container-Content-Terms::before {
  content: '';
  /* background-image: url(../../Images/logo-barter-sin-fondo.png); */
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
  position: absolute;
  background-size: 7%;
}
.Title {
  color: #727272;
  text-transform: uppercase;
  font-family: Helvetica;
  margin-bottom: 0;
}
.Subtitle {
  text-transform: uppercase;
  font-family: Helvetica;
  font-weight: bold;
  margin-top: 10px;
  font-size: 25px;
}

.section-Title {
  font-weight: bold;
}
.section-Body {
  color: #727272;
}
.italic {
  font-style: italic !important;
}
@media (max-width: 600px) {
  .ContainerTerms {
    padding: 0 2%;
  }
  .Container-Content-Terms {
    padding: 2% 4%;
  }
  .Title {
    font-size: 25px;
  }
  .Subtitle {
    font-size: 20px !important;
  }
}
